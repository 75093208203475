import { deleted, get, post } from '../index';
import CustomerRoutes from './Customer.Routes';

const CustomerServices = {
  getCustomers: async (params) => {
    const data = await get(CustomerRoutes.getCustomers, params);
    return data;
  },
  getCustomerDetail: async (id) => {
    const data = await get(CustomerRoutes.getCustomerDetail + id);
    return data;
  },
  getCustomerStripeLink: async (id) =>{
    const data = await get(CustomerRoutes.getCustomerStripeLink + id);
    return data;
  },
  updateCustomer: async (obj) => {
    const data = await post(CustomerRoutes.updateCustomer, obj);
    return data;
  },
  updateCustomerImage: async (obj) => {
    const data = await post(CustomerRoutes.updateCustomerImage, obj);
    return data;
  },
  getCustomerAccountInfo: async (id) => {
    const data = await get(CustomerRoutes.getCustomerAccountInfo + id + '/accountsetupinfo');
    return data;
  },
  customerAccountSetup: async (id) => {
    const data = await post(CustomerRoutes.customerAccountSetup + id + '/accountsetupinfo');
    return data;
  },
  deleteCustomer: async (id) => {
    const data = await deleted(CustomerRoutes.deleteCustomer + id);
    return data;
  },
  getCustomerCurrentPlan: async (id) => {
    const data = await get(CustomerRoutes.getCustomerCurrentPlan + id);
    return data;
  },
  getCustomerPaymentHistory: async (id) => {
    const data = await get(CustomerRoutes.getCustomerPaymentHistory + id);
    return data;
  },
}

export default CustomerServices