import React, { useEffect, useState } from "react";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Button, Modal } from "react-bootstrap";
import "../assets/css/admin.css";
import Layout from "../layout";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import Spinner from "../components/spinner";
import DataTable from "react-data-table-component";
import CouponServices from "../api/coupons/CouponService";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

export default function AdmPlan() {
    const [loader, setLoader] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [couponsList, setCouponsList] = useState([]);
    const [couponId, setCouponId] = useState();
    const [form, setForm] = useState({
        code: '',
        discount_percent: '',
        allowforsetup: 0,
        allowforfees: 0,
    })

    const ActionButtons = (row) => (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "30px",
            }}
        >
            <IconEdit
                size={20}
                style={{ cursor: "pointer" }}
                className="icon-style"
                onClick={() => handleModal(row)}
            />
            <IconTrash
                size={20}
                style={{ cursor: "pointer" }}
                className="icon-style"
                onClick={() => deleteCoupon(row?.id)}
            />
        </div>
    );
    const columns = [
        {
            name: "Code",
            selector: (row) => row.code,
            sortable: true,
        },
        {
            name: "Discount%",
            selector: (row) => row.discount_percent,
            sortable: true,
        },
        {
            name: "Allow on Plan Price",
            selector: (row) => row.allowforfees,
            sortable: true,
        },
        {
            name: "Allow on Setup Fees",
            selector: (row) => row.allowforsetup,
            sortable: true,
        },
        {
            name: "Actions",
            cell: ActionButtons,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const handleModal = (data) => {
        setFormShow(true);
        setCouponId(data.id);
        setForm({
            code: data.code,
            discount_percent: data.discount_percent,
            allowforsetup: (data.allowforsetup==true?1:0),
            allowforfees: (data.allowforfees==true?1:0)
        })
    }
    const addNewRecord = () => {
        setFormShow(true);
        setCouponId(0);
        setForm({
            code: '',
            discount_percent: '',
            allowforsetup: 1,
            allowforfees: 1,
        })
    }
    const setFormObj = (key, value) => {
        setForm(form => {
            return {
                ...form,
                [key]: value
            }
        })
    }
    const getCoupons = async () => {
        setLoader(true);
        try {
            const { data } = await CouponServices.getCoupons();
            setCouponsList(data);
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setLoader(false);
        }
    }
    const deleteCoupon = async (id) => {
        Swal.fire({
            title: "Are you sure you want to delete this Coupon?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#FF0000",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const { message } = await CouponServices.deleteCoupon(id);
                SuccessToaster(message);
                getCoupons();
            }
        });
    };
    const submitForm = async () => {
        let obj = {
            id: couponId,
            code: form.code,
            discount_percent: form.discount_percent,
            allowforsetup: (parseInt(form.allowforsetup)==1?true:false),
            allowforfees: (parseInt(form.allowforfees)==1?true:false),
        };
        setLoader(true);
        try {
            if (couponId > 0) {
                var { message } = await CouponServices.updateCoupon(obj);
                //edit
            } else {
                var { message } = await CouponServices.createCoupon(obj);
                //new
            }
            SuccessToaster(message);
            getCoupons();
            setFormShow(false);
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setLoader(false);
        }
    }
    useEffect(() => {
        getCoupons()
    }, [])
    return (
        <Layout>
            <div class="content-wrapper">
                <div class="container-xxl flex-grow-1 container-p-y responsive-width-control">
                    <div className="card p-4 mt-3">
                        <h2 className="text-center mb-2 fw-bold">Coupons</h2>
                        <div className="container">
                            <div className="row">
                                <div class="col-3">

                                </div>
                                <div class="col-3">
                                </div>
                                <div class="col-4"></div>
                                <div class="col-2">
                                    <button
                                        type="button"
                                        class="btn btn-warning"
                                        onClick={addNewRecord}
                                    >
                                        Add New
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-datatable table-responsive">
                            {loader ? (
                                <Spinner />
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={couponsList}
                                    pagination
                                    paginationPerPage={10}
                                    responsive={true}
                                    overflowY={true}
                                    highlightOnHover
                                    className="secondaryCheckbox"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Create/Edit Modal */}
            <Modal
                show={formShow}
                onHide={() => setFormShow(false)} // passing true because we want to close editModal
                size="lg"
                centered
                className="modal-form custom-modal-form-style"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{couponId > 0 ? 'Edit' : 'Create'} Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <div>
                            <label>Code:</label>
                            <input
                                type="text"
                                className="mb-1"
                                value={form.code}
                                onChange={e => setFormObj('code', e.target.value)}
                            />

                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <label className="mb-1">Discount %:</label>
                                <input
                                    type="number"
                                    className="mb-1"
                                    value={form.discount_percent}
                                    onChange={e => setFormObj('discount_percent', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <label className="mb-1">Allow on Setup Fees:</label>
                                <select className="mb-1" value={form.allowforsetup} onChange={e => setFormObj('allowforsetup', e.target.value)}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                            <div className="col">
                                <label className="mb-1">Allow on Plan Price:</label>
                                <select className="mb-1" value={form.allowforfees} onChange={e => setFormObj('allowforfees', e.target.value)}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setFormShow(false) }}>
                        Close
                    </Button>
                    <Button onClick={submitForm} variant="primary">
                        {couponId > 0 ? 'Update' : 'Save'} Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
}
