import React from "react";
import { useForm } from "react-hook-form";

const AddSidebar = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("🚀 ~ file: addSidebar.js:13 ~ onSubmit ~ data:", data);
    reset();
  };
  return (
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasEcommerceCustomerAdd"
      aria-labelledby="offcanvasEcommerceCustomerAddLabel"
    >
      <div class="offcanvas-header">
        <h5 id="offcanvasEcommerceCustomerAddLabel" class="offcanvas-title">
          Add Customer
        </h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body mx-0 flex-grow-0">
        <form
          class="ecommerce-customer-add pt-0"
          id="eCommerceCustomerAddForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div class="ecommerce-customer-add-basic mb-3">
            <h6 class="mb-3">Basic Information</h6>
            <div class="mb-3">
              <label class="form-label" for="ecommerce-customer-add-name">
                Name*
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="John Doe"
                {...register("name", { required: "Name is Required" })}
              />
              {errors.name && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.name.message}
                </small>
              )}
            </div>
            <div class="mb-3">
              <label class="form-label" for="ecommerce-customer-add-email">
                Email*
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="john.doe@example.com"
                {...register("email", { required: "Email is Required" })}
              />
              {errors.email && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.email.message}
                </small>
              )}
            </div>
            <div>
              <label class="form-label" for="ecommerce-customer-add-contact">
                Mobile
              </label>
              <input
                type="text"
                class="form-control phone-mask"
                placeholder="+(123) 456-7890"
                {...register("mobile", {
                  required: "Mobile Number is Required",
                })}
              />
              {errors.mobile && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.mobile.message}
                </small>
              )}
            </div>
          </div>

          <div class="ecommerce-customer-add-shiping mb-3 pt-3">
            <h6 class="mb-3">Shipping Information</h6>
            <div class="mb-3">
              <label class="form-label" for="ecommerce-customer-add-address">
                Address Line 1
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="45 Roker Terrace"
                {...register("address1", {
                  required: "Address Line 1 is Required",
                })}
              />
              {errors.address1 && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.address1.message}
                </small>
              )}
            </div>
            <div class="mb-3">
              <label class="form-label" for="ecommerce-customer-add-address-2">
                Address Line 2
              </label>
              <input
                type="text"
                class="form-control"
                {...register("address2", {
                  required: "Address Line 2 is Required",
                })}
              />
              {errors.address2 && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.address2.message}
                </small>
              )}
            </div>
            <div class="mb-3">
              <label class="form-label" for="ecommerce-customer-add-town">
                Town
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="New York"
                {...register("town", {
                  required: "Town is Required",
                })}
              />
              {errors.town && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.town.message}
                </small>
              )}
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-6">
                <label class="form-label" for="ecommerce-customer-add-state">
                  State / Province
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Southern tip"
                  {...register("StateAndProvince", {
                    required: "State and Province is Required",
                  })}
                />
                {errors.StateAndProvince && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.StateAndProvince.message}
                  </small>
                )}
              </div>
              <div class="col-12 col-sm-6">
                <label
                  class="form-label"
                  for="ecommerce-customer-add-post-code"
                >
                  Post Code
                </label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="734990"
                  {...register("post_code", {
                    required: "Post Code is Required",
                    pattern: "[0-9]{8}",
                    maxLength: 8,
                  })}
                  // pattern="[0-9]{8}"
                  // maxlength="8"
                />
                {errors.post_code && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.post_code.message}
                  </small>
                )}
              </div>
            </div>
            <div>
              <label class="form-label" for="ecommerce-customer-add-country">
                Country
              </label>
              <select
                {...register("country", {
                  required: true,
                })}
                class="select2 form-select"
              >
                <option value="Australia">Australia</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Belarus">Belarus</option>
                <option value="Brazil">Brazil</option>
                <option value="Canada">Canada</option>
                <option value="China">China</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Japan">Japan</option>
                <option value="Korea">Korea, Republic of</option>
                <option value="Mexico">Mexico</option>
                <option value="Philippines">Philippines</option>
                <option value="Russia">Russian Federation</option>
                <option value="South Africa">South Africa</option>
                <option value="Thailand">Thailand</option>
                <option value="Turkey">Turkey</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
              </select>
            </div>
          </div>

          <div class="d-sm-flex mb-3 pt-3">
            <div class="me-auto mb-2 mb-md-0">
              <h6 class="mb-0">Use as a billing address?</h6>
              <small class="text-muted">
                If you need more info, please check budget.
              </small>
            </div>
            <label class="switch m-auto pe-2">
              <input
                type="checkbox"
                class="switch-input"
                {...register("useBillAddress")}
              />
              <span class="switch-toggle-slider">
                <span class="switch-on"></span>
                <span class="switch-off"></span>
              </span>
            </label>
          </div>
          <div class="pt-3">
            <button
              type="submit"
              class="btn btn-primary me-sm-3 me-1 data-submit"
            >
              Add
            </button>
            <button
              type="reset"
              class="btn btn-label-danger"
              data-bs-dismiss="offcanvas"
            >
              Discard
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSidebar;
