import { IconCopy } from "@tabler/icons-react";
import React from "react";
import girlWithPhone from "../assets/img/illustrations/add-new-roles.png";
import AcessRoleTable from "../components/acessRoleTable";
import AddModal from "../components/addModal";
import AvatorList from "../components/avatorList";
import Layout from "../layout";
const AcessRole = () => {
  return (
    <Layout>
      {/* // <!-- Content wrapper --> */}
      <div class="content-wrapper mt-4">
        {/* <!-- Content --> */}

        <div class="container-xxl flex-grow-1 container-p-y">
          <h4 class="mb-4">Roles List</h4>

          <p class="mb-4">
            A role provided access to predefined menus and features so that
            depending on <br />
            assigned role an administrator can have access to what user needs.
          </p>
          {/* <!-- Role cards --> */}
          <div class="row g-4">
            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <h6 class="fw-normal mb-2">Total 4 users</h6>
                    <AvatorList />
                  </div>
                  <div class="d-flex justify-content-between align-items-end mt-1">
                    <div class="role-heading">
                      <h4 class="mb-1">Administrator</h4>
                      <a
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#addRoleModal"
                        class="role-edit-modal"
                      >
                        <span>Edit Role</span>
                      </a>
                    </div>
                    <a href="javascript:void(0);" class="text-muted">
                      <IconCopy />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <h6 class="fw-normal mb-2">Total 7 users</h6>
                    <AvatorList />
                  </div>
                  <div class="d-flex justify-content-between align-items-end mt-1">
                    <div class="role-heading">
                      <h4 class="mb-1">Manager</h4>
                      <a
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#addRoleModal"
                        class="role-edit-modal"
                      >
                        <span>Edit Role</span>
                      </a>
                    </div>
                    <a href="javascript:void(0);" class="text-muted">
                      <IconCopy />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <h6 class="fw-normal mb-2">Total 5 users</h6>
                    <AvatorList />
                  </div>
                  <div class="d-flex justify-content-between align-items-end mt-1">
                    <div class="role-heading">
                      <h4 class="mb-1">Users</h4>
                      <a
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#addRoleModal"
                        class="role-edit-modal"
                      >
                        <span>Edit Role</span>
                      </a>
                    </div>
                    <a href="javascript:void(0);" class="text-muted">
                      <IconCopy />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <h6 class="fw-normal mb-2">Total 3 users</h6>
                    <AvatorList />
                  </div>
                  <div class="d-flex justify-content-between align-items-end mt-1">
                    <div class="role-heading">
                      <h4 class="mb-1">Support</h4>
                      <a
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#addRoleModal"
                        class="role-edit-modal"
                      >
                        <span>Edit Role</span>
                      </a>
                    </div>
                    <a href="javascript:void(0);" class="text-muted">
                      <IconCopy />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <h6 class="fw-normal mb-2">Total 2 users</h6>
                    <AvatorList />
                  </div>
                  <div class="d-flex justify-content-between align-items-end mt-1">
                    <div class="role-heading">
                      <h4 class="mb-1">Restricted User</h4>
                      <a
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#addRoleModal"
                        class="role-edit-modal"
                      >
                        <span>Edit Role</span>
                      </a>
                    </div>
                    <a href="javascript:void(0);" class="text-muted">
                      <IconCopy />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
              <div class="card h-100">
                <div class="row h-100">
                  <div class="col-sm-5">
                    <div class="d-flex align-items-end h-100 justify-content-center mt-sm-0 mt-3">
                      <img
                        src={girlWithPhone}
                        class="img-fluid mt-sm-4 mt-md-0"
                        alt="add-new-roles"
                        width="83"
                      />
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <div class="card-body text-sm-end text-center ps-sm-0">
                      <button
                        data-bs-target="#addRoleModal"
                        data-bs-toggle="modal"
                        class="btn btn-primary mb-2 text-nowrap add-new-role"
                      >
                        Add New Role
                      </button>
                      <p class="mb-0 mt-1">Add role, if it does not exist</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              {/* <!-- Role Table --> */}
              <div class="card">
                <div class="card-datatable table-responsive">
                  {/* // table nav */}
                  <div class="row mx-2 px-2 pt-4 pb-2">
                    <div class="col-sm-12 col-md-4 col-lg-6">
                      <div
                        class="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label className="d-flex align-items-center gap-2">
                          Show
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            class="form-select"
                            style={{ width: "80px" }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-6">
                      <div class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center align-items-center flex-sm-nowrap flex-wrap me-1">
                        <div class="me-3">
                          <div
                            id="DataTables_Table_0_filter"
                            class="dataTables_filter"
                          >
                            <label className="d-flex align-items-center gap-3">
                              Search
                              <input
                                type="search"
                                class="form-control"
                                placeholder="Search.."
                                aria-controls="DataTables_Table_0"
                              />
                            </label>
                          </div>
                        </div>
                        <div class="user_role w-px-200 pb-3 pb-sm-0">
                          <select
                            id="UserRole"
                            class="form-select text-capitalize"
                          >
                            <option value=""> Select Role </option>
                            <option value="Admin" class="text-capitalize">
                              Admin
                            </option>
                            <option value="Author" class="text-capitalize">
                              Author
                            </option>
                            <option value="Editor" class="text-capitalize">
                              Editor
                            </option>
                            <option value="Maintainer" class="text-capitalize">
                              Maintainer
                            </option>
                            <option value="Subscriber" class="text-capitalize">
                              Subscriber
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <AcessRoleTable />
                </div>
              </div>
              {/* <!--/ Role Table --> */}
            </div>
          </div>
          {/* <!--/ Role cards --> */}

          {/* <!-- Add Role Modal --> */}
          <AddModal />
        </div>
        {/* <!-- / Content --> */}

        <div class="content-backdrop fade"></div>
      </div>
    </Layout>
  );
};

export default AcessRole;
