import "flatpickr/dist/themes/material_green.css"; // Import the theme you want
import React from "react";
import Flatpickr from "react-flatpickr";

const TimersPick = ({ value, onChange, disabled }) => {


  const handleTimeChange = (selectedDates) => {
    onChange(selectedDates[0]);
  };

  return (
    <Flatpickr
      data-enable-time
      value={value}
      onChange={handleTimeChange}
      options={{
        noCalendar: true,
        enableTime: true,
        dateFormat: "H:i",
      }}
      disabled={disabled}
    />
  );
};

export default TimersPick;
