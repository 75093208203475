import { get, put, post } from '../index';
import ProjectRoutes from './Project.Routes';

const ProjectServices = {
  getProjectName: async (params) => {
    const data = await get(ProjectRoutes.getProjectName, params);
    return data;
  },
  updateProjectName: async (obj) => {
    const data = await post(ProjectRoutes.updateProjectName, obj);
    return data;
  },
  getProjectDetail: async (params) => {
    const data = await get(ProjectRoutes.getProjectDetail, params);
    return data;
  },
  updateProject: async (obj) => {
    const data = await put(ProjectRoutes.updateProject, obj);
    return data;
  },
}

export default ProjectServices