import { get, post, deleted } from '../index';
import SitemapRoutes from './Sitemap.Routes';

const SitemapServices = {
  getSitemaps: async (params) => {
    const data = await get(SitemapRoutes.getSitemaps, params);
    return data;
  },
  addSitemap: async (obj) => {
    const data = await post(SitemapRoutes.addSitemap, obj);
    return data;
  },
  uploadSitemap: async (id, obj) => {
    const data = await post(SitemapRoutes.uploadSitemap + `projectId=${id}`, obj);
    return data;
  },
  deleteSitemap: async (params) => {
    const data = await deleted(SitemapRoutes.deleteSitemap, params);
    return data;
  },
}

export default SitemapServices