import React, { useState } from "react";
import { useForm } from "react-hook-form";

const AddModal = () => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const optionsData = [
    {
      role: "User Management",
    },
    {
      role: "Content Management",
    },

    {
      role: "Disputes Management",
    },

    {
      role: "Database Management",
    },

    {
      role: "Financial Management",
    },

    {
      role: "Reporting",
    },

    {
      role: "API Control",
    },
    {
      role: "Repository Management",
    },
    {
      role: "Payroll",
    },
  ];

  // for handling all checks
  const handleSelectAll = (event) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.id !== "selectAll") {
        checkbox.checked = event.target.checked;
      }
    });
    setSelectAllChecked(event.target.checked);
  };

  // handle Submit
  const onSubmit = (data) => {
    console.log("🚀 ~ file: addModal.js:62 ~ onSubmit ~ data:", data);
    return {};
  };
  return (
    <div class="modal fade" id="addRoleModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-add-new-role">
        <div class="modal-content p-3 p-md-5">
          <button
            type="button"
            class="btn-close btn-pinned"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-body">
            <div class="text-center mb-4">
              <h3 class="role-title mb-2">Add New Role</h3>
              <p class="text-muted">Set role permissions</p>
            </div>
            {/* <!-- Add role form --> */}
            <form
              id="addRoleForm"
              class="row g-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div class="col-12 mb-4">
                <label class="form-label" for="modalRoleName">
                  Role Name
                </label>
                <input
                  type="text"
                  id="modalRoleName"
                  name="modalRoleName"
                  class="form-control"
                  placeholder="Enter a role name"
                  tabindex="-1"
                  {...register("roleName", {
                    required: "Role name is required",
                  })}
                />
                {errors.roleName && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.roleName.message}
                  </small>
                )}
              </div>
              <div class="col-12">
                <h5>Role Permissions</h5>
                {/* <!-- Permission table --> */}
                <div class="table-responsive">
                  <table class="table table-flush-spacing">
                    <tbody>
                      <tr>
                        <td class="text-nowrap fw-medium">
                          Administrator Access
                          <i
                            class="ti ti-info-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Allows a full access to the system"
                          ></i>
                        </td>
                        <td>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="selectAll"
                              onChange={handleSelectAll}
                              checked={selectAllChecked}
                            />
                            <label class="form-check-label" for="selectAll">
                              {" "}
                              Select All{" "}
                            </label>
                          </div>
                        </td>
                      </tr>
                      {optionsData.map((options) => (
                        <tr>
                          <td class="text-nowrap fw-medium">{options.role}</td>
                          <td>
                            <div class="d-flex">
                              <div class="form-check me-3 me-lg-5">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="userManagementRead"
                                  {...register(
                                    `${options.role.split(" ").join("_")}_Read`
                                  )}
                                />
                                <label
                                  class="form-check-label"
                                  for="userManagementRead"
                                >
                                  Read
                                </label>
                              </div>
                              <div class="form-check me-3 me-lg-5">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="userManagementWrite"
                                  {...register(
                                    `${options.role.split(" ").join("_")}_Write`
                                  )}
                                />
                                <label
                                  class="form-check-label"
                                  for="userManagementWrite"
                                >
                                  Write
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="userManagementCreate"
                                  {...register(
                                    `${options.role.split(" ").join("_")}Create`
                                  )}
                                />
                                <label
                                  class="form-check-label"
                                  for="userManagementCreate"
                                >
                                  Create
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <!-- Permission table --> */}
              </div>
              <div class="col-12 text-center mt-4">
                <button type="submit" class="btn btn-primary me-sm-3 me-1">
                  Submit
                </button>
                <button
                  type="reset"
                  class="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </form>
            {/* <!--/ Add role form --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
