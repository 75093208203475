import React, { useEffect, useState } from "react";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import "../assets/css/sidebar.css";
// import { initialFaqs } from "../utils/faqs";
import Layout from "../layout";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import FaqServices from "../api/faq/FaqService";

export default function Faq() {

  const [modalShow, setModalShow] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [faqs, setFaqs] = useState();

  // For Get Faqs
  const getFaqs = async () => {
    try {
      const { data } = await FaqServices.getFaqs()
      setFaqs(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const createFaq = async (formData) => {
    try {
      let obj = {
        question: formData?.question,
        answer: formData?.answer,
      }
      const { message } = await FaqServices.createFaq(obj)
      SuccessToaster(message)
      getFaqs()
      modaloff()
    } catch (error) {
      ErrorToaster(error)
    }
  };

  const modaloff = () => {
    setModalShow(false);
    reset();
  };

  const deleteFaq = (id) => {
    try {
      Swal.fire({
        title: "Are you sure you would like to delete this question?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { message } = await FaqServices.deleteFaq(id)
          SuccessToaster(message)
          getFaqs()
        }
      });
    } catch (error) {
      ErrorToaster(error)
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <Layout>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mt-3">
              <div className="row">
                <div className="col-sm-12">
                  <section className="py-5 pricing-faqs rounded-bottom bg-body">
                    <div className="container">
                      <div className="text-center mb-5">
                        <h2 className="mb-2">
                          <b>Frequently Asked Questions</b>
                        </h2>
                        <div class="center-div">
                          <a href="#">Getting Started</a>
                          <div class="divider"></div>
                          <a href="#">How It Works</a>
                          <div class="divider"></div>
                          <a href="#">Fix A Problem</a>
                          <div class="divider"></div>
                          <a href="#">Plan, Payments, Billing</a>
                        </div>
                        <p>
                          Let us help answer the most common questions you might
                          have.
                        </p>
                        <h1 className="font-weight-bold">
                          <b>Getting Started</b>
                        </h1>
                      </div>

                      <div class="float-right text-right mb-3">
                        <button
                          type="button"
                          class="btn btn-warning"
                          onClick={() => setModalShow(true)}
                        >
                          Add New
                        </button>
                      </div>
                      {faqs?.length > 0 &&
                        <Accordion
                          defaultActiveKey="1"
                          flush
                          className="accordion-style"
                        >
                          {/* ----------------------------- */}
                          {faqs.map((item, index) => (
                            <Accordion.Item
                              eventKey={index + 1}
                              className="position-relative accordion-space"
                            >
                              <Accordion.Header>
                                <span className="accordion-head-text">
                                  {item?.question}
                                </span>
                              </Accordion.Header>

                              <Accordion.Body className="mt-3">
                                <p>{item?.answer}</p>
                              </Accordion.Body>
                              <div class="form-check me-2 accordion-check">
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => deleteFaq(item?.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </Accordion.Item>
                          ))}

                          {/* ------------------------------ */}
                        </Accordion>
                      }
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div class="content-backdrop fade"></div>
        </div>
      </div>

      <Modal
        show={modalShow}
        onHide={() => modaloff()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(createFaq)}>
            <Form.Group controlId="formQuestion">
              <Form.Label className="fw-bold display-3">Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your question"
                {...register("question", { required: true })}
              />
              {errors.question && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  Question is Required
                </small>
              )}
            </Form.Group>
            <br />
            <Form.Group controlId="formAnswer">
              <Form.Label className="fw-bold display-3">Answer</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your answer"
                {...register("answer", { required: true })}
              />
              {errors.answer && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  Answer is Required
                </small>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modaloff}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit(createFaq)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
