import axios from 'axios';

export const baseUrl = 'https://api.krissai.online'

const instance = axios.create({
    baseURL: baseUrl
});

instance.interceptors.request.use((request) => {

    let user = JSON.parse(localStorage.getItem('user'))

    request.headers = {
        'Accept': "application/json, text/plain, */*",
        'token': `${user?.token}`,
    }
    return request
});

instance.interceptors.response.use((response, cookies) => {
    if (response) {
        return response
    }
}, (error) =>
    Promise.reject(
        error
    )
);

export default instance;