import { IconEye, IconEyeOff } from "@tabler/icons-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import bgLogin from "../assets/img/illustrations/bglogin.png";
import login1 from "../assets/img/illustrations/login3.png";
import Logo from "../assets/img/logo.svg";
import "../assets/vendor/css/pages/page-auth.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import AuthServices from "../api/auth/AuthService";
import { ErrorToaster } from "../components/Toaster";
import { useAuth } from "../context/UseContext";

export default function AdmLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { userLogin } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    try {
      let obj = {
        email: formData?.email,
        password: formData?.password
      }
      const { user } = await AuthServices.login(obj)
      userLogin(user);
    } catch (error) {
      ErrorToaster('You have entered an invalid email or password.')
    }
  };

  return (
    <div className="light-style layout-wide customizer-hide">
      <div class="authentication-wrapper authentication-cover authentication-bg">
        <div class="authentication-inner row">
          <div class="d-none d-lg-flex col-lg-7 p-0">
            <div class="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
              <img
                src={login1}
                alt="auth-login-cover"
                class="img-fluid my-5 auth-illustration"
                data-app-light-img="illustrations/auth-login-illustration-light.png"
              />
              <img
                src={bgLogin}
                alt="auth-login-cover"
                class="platform-bg"
                data-app-light-img="illustrations/bg-shape-image-light.png"
                data-app-dark-img="illustrations/bg-shape-image-dark.png"
              />
            </div>
          </div>

          <div class="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
            <div class="w-px-400 mx-auto">
              <div class="app-brand mb-4">
                <a href="/" class="app-brand-link gap-2">
                  <span class="app-brand-logo">
                    <img src={Logo} />
                  </span>
                </a>
              </div>
              <p class="mb-4">
                Please sign-in to your account and start the adventure
              </p>

              <form
                id="formAuthentication"
                class="mb-3"
                // onFinish={onFinish} 
                // form={form}
                // action="index.html"
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="mb-3">
                  <label for="email" class="form-label">
                    Email or Username
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    name="email-username"
                    placeholder="Enter your email or username"
                    autofocus
                    {...register("email", {
                      required: "Email is Required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <small className="d-block text-danger ms-1 mt-1 ">
                      {errors.email.message}
                    </small>
                  )}
                </div>
                <div class="mb-3 form-password-toggle">
                  <div class="d-flex justify-content-between">
                    <label class="form-label" for="password">
                      Password
                    </label>
                    {/* <a href="auth-forgot-password-cover.html">
                      <small className="created">Forgot Password?</small>
                    </a> */}
                  </div>
                  <div class="input-group input-group-merge">
                    <input
                      className="form-control"
                      name="currentPassword"
                      id="currentPassword"
                      type={`${!showPassword ? "password" : "text"}`}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      {...register("password", {
                        required: "Password is Required",
                        minLength: {
                          value: 6,
                          message: "Password must have at least 6 characters",
                        },
                      })}
                    />
                    <span className=" input-group-text cursor-pointer">
                      {showPassword ? (
                        <IconEye onClick={() => setShowPassword(false)} />
                      ) : (
                        <IconEyeOff onClick={() => setShowPassword(true)} />
                      )}
                    </span>
                  </div>
                  {errors.password && (
                    <small className="d-block text-danger ms-1 mt-1 ">
                      {errors.password.message}
                    </small>
                  )}
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="remember-me"
                      {...register("rememberMe")}
                    />
                    <label class="form-check-label" for="remember-me">
                      {" "}
                      Remember Me{" "}
                    </label>
                  </div>
                </div>
                <button class="btn btn-primary d-grid w-100" type="submit">
                  Sign in
                </button>
              </form>

              {/* <p class="text-center">
                <span>New on our platform?</span>
                <Link to={'/register'}>
                  <span className="created"> Create an account</span>
                </Link>

              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
