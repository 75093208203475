import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
// Random data generator function
const generateRandomSalesData = () => {
  // You would implement your own logic here to generate random data
  // For example purposes, this just modifies the sales value
  return {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": {
          "sales": Math.floor(Math.random() * 600), // Random sales between 0 and 600
          "team": "Team A"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-99.140625, 39.368279],
              [-101.601563, 36.173357],
              [-94.042969, 33.431441],
              [-89.648438, 36.879621],
              [-99.140625, 39.368279]
            ]
          ]
        }
      },
      // ... Add more features here if needed
    ]
  };
};
// Component
const SalesTeamMap = () => {
  const [salesData, setSalesData] = useState(generateRandomSalesData());
  useEffect(() => {
    // This will be called on component mount
    setSalesData(generateRandomSalesData()); // Update the sales data with random values
  }, []);
  const getColor = (sales) => {
    return sales > 500 ? '#006837' :
           sales > 250 ? '#31a354' :
           sales > 100 ? '#78c679' :
           sales > 50  ? '#addd8e' :
           sales > 20  ? '#d9f0a3' :
           sales > 10  ? '#f7fcb9' :
           sales > 0   ? '#ffffe5' :
                         '#ffffcc'; // Assuming this is the color for zero sales
  }
  const mapStyle = {
    height: '500px',
    width: '100%',
    
  };
  const style = (feature) => {
    return {
      fillColor: getColor(feature.properties.sales),
     
    };
  };
  return (
    <MapContainer center={[41.9, 12.5]} zoom={1.5} style={mapStyle}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoJSON data={salesData} style={style} />
    </MapContainer>
  );
};
export default SalesTeamMap;