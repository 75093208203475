import React from "react";
import oneImage from "../assets/img/avatars/1.png";
import twleveImage from "../assets/img/avatars/12.png";
import threeImage from "../assets/img/avatars/3.png";
import fiveImage from "../assets/img/avatars/5.png";
import sixImage from "../assets/img/avatars/6.png";

const AvatorList = ({ quantity }) => {
  const avatorsList = [
    {
      src: fiveImage,
    },
    {
      src: twleveImage,
    },
    {
      src: sixImage,
    },
    {
      src: threeImage,
    },
    {
      src: oneImage,
    },
  ];
  return (
    <ul class="list-unstyled d-flex align-items-center avatar-group mb-0">
      {quantity
        ? avatorsList.map((avat, i) =>
            i <= 2 ? (
              <li
                data-bs-toggle="tooltip"
                data-popup="tooltip-custom"
                data-bs-placement="top"
                title="Vinnie Mostowy"
                class="avatar avatar-sm pull-up"
              >
                <img class="rounded-circle" src={avat.src} alt="Avatar" />
              </li>
            ) : (
              ""
            )
          )
        : avatorsList.map((avat) => (
            <li
              data-bs-toggle="tooltip"
              data-popup="tooltip-custom"
              data-bs-placement="top"
              title="Vinnie Mostowy"
              class="avatar avatar-sm pull-up"
            >
              <img class="rounded-circle" src={avat.src} alt="Avatar" />
            </li>
          ))}
    </ul>
  );
};

export default AvatorList;
