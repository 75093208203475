import { IconCode, IconCopy, IconLink, IconMessage } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import "../assets/css/data.scss";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import ProjectServices from "../api/project/ProjectServices";
import { ErrorToaster, SuccessToaster } from "./Toaster";

export default function SharingTab({ projectId }) {

  const [loading, setLoading] = useState(false);

  const [isEnabled, setIsEnabled] = useState(false)

  const [check, setCheck] = useState(1);
  const handleChange = (val) => {
    setCheck(val);
  };

  const copyLink = (value) => {
    navigator.clipboard.writeText(value);
  };

  const [projectDetail, setProjectDetail] = useState();

  const getProjectDetail = async () => {
    try {
      let params = {
        projectId: projectId,
      };
      const { data } = await ProjectServices.getProjectDetail(params);
      setProjectDetail(data);
      setIsEnabled(data?.is_shared)
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const updateProject = async () => {
    setLoading(true)
    try {
      let obj = {
        projectId: projectId,
        is_shared: isEnabled
      }
      const { message } = await ProjectServices.updateProject(obj)
      SuccessToaster(message)
      getProjectDetail()
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getProjectDetail()
  }, []);

  return (
    <>
      <div className="settings-body mt-6 shadow-sm shadow-black rounded bg-white transition">
        <div className="setting-header border-b p-4">
          <span className="h3 fw-bold ">Sharing</span>
        </div>
        <div className="setting-content">
          <div className="row justify-content-around m-0">
            <div className="col-12 col-xl-3 left_side">
              <div
                onClick={() => handleChange(1)}
                className="row  mx-2 subtab d-flex flex-col my-4 align-items-center active cursor-pointer"
              >
                <div
                  className={` ${check == 1 ? "bg-primary text-white" : "bg_grey"
                    } subtab-icon shadow-sm shadow-black rounded p-2  col-2`}
                  style={{ width: 40, height: 40 }}
                >
                  <IconLink />
                </div>
                <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-9">
                  <div className="subtab-title">
                    <span className="fw-bold">Share Link</span>
                  </div>
                  <div className="subtab-description">
                    <p className="text-muted m-0">Share your bot with anyone</p>
                  </div>
                </div>
              </div>
              {/* second */}
              <div
                onClick={() => handleChange(2)}
                className="row  mx-2 subtab d-flex flex-col my-4 align-items-center active cursor-pointer"
              >
                <div
                  className={`${check == 2 ? "bg-primary text-white" : "bg_grey"
                    } subtab-icon shadow-sm shadow-black rounded p-2  col-2`}
                  style={{ width: 40, height: 40 }}
                >
                  <IconCode />
                </div>
                <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-9">
                  <div className="subtab-title">
                    <span className="fw-bold">Embed</span>
                  </div>
                  <div className="subtab-description">
                    <p className="text-muted m-0">
                      Put your bot on your website or helpdesk.
                    </p>
                  </div>
                </div>
              </div>

              <div
                onClick={() => handleChange(3)}
                className="row  mx-2 subtab d-flex flex-col my-4 align-items-center active cursor-pointer"
              >
                <div
                  className={` ${check == 3 ? "bg-primary text-white" : "bg_grey"
                    } subtab-icon shadow-sm shadow-black rounded p-2 col-2`}
                  style={{ width: 40, height: 40 }}
                >
                  <IconMessage />
                </div>
                <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-9">
                  <div className="subtab-title">
                    <span className="fw-bold">Live Chat</span>
                  </div>
                  <div className="subtab-description">
                    <p className="text-muted m-0">
                      Live chat on website or helpdesk
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {check == 1 && (
              <div className="col-12 col-xl-9 right_side">
                <div className="fw-bold">Copy link</div>
                <div className="d-flex">
                  <input
                    type="text"
                    class="form-control w-75"
                    id="exampleInput"
                    placeholder=""
                    value={projectDetail?.shareable_link}
                  />

                  <button type="button" className="btn btn-outline-primary ml-2" onClick={() => copyLink(projectDetail?.shareable_link)}>
                    Copy Link
                  </button>
                </div>
                <span className="text-muted mt-2 d-block">
                  You can copy and share this link with your team or client.
                </span>

                <div class="form-check form-switch d-flex align-items-center" onClick={() => setIsEnabled(!isEnabled)}>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={isEnabled}
                    style={{ width: "46px", height: "30px" }}
                  />
                  <label
                    class="form-check-label mx-2"
                    for="flexSwitchCheckChecked"
                  >
                    Enable share page
                  </label>
                </div>
                <button disabled={loading} type="button" className="btn btn-primary mt-3" onClick={() => updateProject()}>
                  Save Changes
                </button>
              </div>
            )}
            {check == 2 && (
              <div className="col-12 col-xl-9 right_side">
                <div className="fw-bold">Embed script</div>
                <br />
                <div class="form-check form-switch d-flex align-items-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    style={{ width: "46px", height: "30px" }}
                    checked
                  />
                  <label
                    class="form-check-label mx-2"
                    for="flexSwitchCheckChecked"
                  >
                    Enable embed
                  </label>
                </div>
                <br />
                <div className="d-flex">
                  <div style={{ width: "15%" }}>Width</div>
                  <div className="d-flex">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInput"
                      placeholder=""
                      defaultValue={100}
                    />
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option value="1">px</option>
                      <option selected>%</option>
                    </select>
                  </div>
                </div>
                <br />
                <div className="d-flex flex-wrap">
                  <div style={{ width: "15%" }}>Height</div>
                  <div style={{ width: "285px" }}>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected value="1">
                        Fixed
                      </option>
                      <option>Auto</option>
                    </select>
                  </div>
                  <div className="d-flex mx-4">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInput"
                      placeholder=""
                      defaultValue={500}
                    />
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option value="1">px</option>
                      <option selected>%</option>
                    </select>
                  </div>
                </div>
                <br />
                <div className="d-flex">
                  <div style={{ width: "15%" }}>AFFID</div>
                  <div className="d-flex">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="AFFID"
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex">
                  <div style={{ width: "15%" }}>Prompt</div>
                  <div className="d-flex">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Prompt"
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex">
                  <div style={{ width: "15%" }}>External ID</div>
                  <div className="d-flex">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="External ID"
                    />
                  </div>
                </div>
                <br />
                <div class="form-check form-switch d-flex align-items-center mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    style={{ width: "46px", height: "30px" }}
                    defaultChecked={true}
                  />
                  <label
                    class="form-check-label mx-2 text-black"
                    for="flexSwitchCheckChecked"
                  >
                    Automatically reset the previous conversation
                  </label>
                </div>
                <div
                  className="embed bg-body position-relative rounded border shadow-sm mt-6 p-3 mob-width"
                  style={{ overflow: "scroll" }}
                >
                  <div className="w-100 d-flex justify-content-end" style={{ cursor: 'pointer' }} onClick={() => copyLink(projectDetail?.embed_code)}>
                    <IconCopy />
                  </div>
                  <p className="text-muted">
                    <blockquote>
                      {projectDetail?.embed_code}
                    </blockquote>
                  </p>
                </div>
                <p className="text-muted mt-2">
                  Copy the following code and paste it into your site’s section,
                  where you want the chatbot box and generated responses to
                  render.
                </p>
                <p>
                  <span className="fw-bold">[Advanced Users]</span>
                  Restrict access to embedding your chatbot only on allowed
                  domains. Provide domains list separated by spaces, tabs, new
                  lines or commas. Input domain without scheme (e.g.
                  domain.com). You can use * mark as placeholder which mean any
                  count of chars (e.g. *.domain.com will allow any subdomains in
                  domain.com).
                </p>
                <textarea
                  class="form-control mb-4"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  placeholder="domain.com domain2.com sub.domain3.com *.domain4.com"
                ></textarea>
                <button type="button" className="btn btn-primary mt-3">
                  Save Domains
                </button>
              </div>
            )}
            {check == 3 && (
              <div className="col-12 col-xl-9 right_side">
                <div className="fw-bold">Embed script</div>
                <br />
                <div class="form-check form-switch d-flex align-items-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    style={{ width: "46px", height: "30px" }}
                    defaultChecked={true}
                  />
                  <label
                    class="form-check-label mx-2"
                    for="flexSwitchCheckChecked"
                  >
                    Enable Live Chat
                  </label>
                </div>
                <br />
                <div class="form-check form-switch d-flex align-items-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    style={{ width: "46px", height: "30px" }}
                  />
                  <label
                    class="form-check-label mx-2"
                    for="flexSwitchCheckChecked"
                  >
                    Automatically pop up the chat bubble
                  </label>
                </div>
                <br />
                <div class="form-check form-switch d-flex align-items-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    style={{ width: "46px", height: "30px" }}
                  />
                  <label
                    class="form-check-label mx-2"
                    for="flexSwitchCheckChecked"
                  >
                    Automatically reset the previous conversation
                  </label>
                </div>
                <br />
                <div className="d-flex">
                  <div style={{ width: "15%" }}>AFFID</div>
                  <div className="d-flex">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="AFFID"
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex">
                  <div style={{ width: "15%" }}>Prompt</div>
                  <div className="d-flex">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Prompt"
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex">
                  <div style={{ width: "15%" }}>External ID</div>
                  <div className="d-flex">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="External ID"
                    />
                  </div>
                </div>
                <br />

                <div
                  className="embed bg-body position-relative rounded border shadow-sm mt-6 p-3 mob-width"
                  style={{ overflow: "scroll" }}
                >
                  <div className="w-100 d-flex justify-content-end" style={{ cursor: 'pointer' }} onClick={() => copyLink(projectDetail?.live_chat_code)}>
                    <IconCopy />
                  </div>
                  <p className="text-muted">
                    <blockquote>
                      {projectDetail?.live_chat_code}
                    </blockquote>
                  </p>
                </div>
                <p className="text-muted mt-2">
                  Copy the following code and paste it into your site’s section,
                  where you want the chatbot box and generated responses to
                  render.
                </p>
                <p>
                  <span className="fw-bold">[Advanced Users]</span>
                  Restrict access to embedding your chatbot only on allowed
                  domains. Provide domains list separated by spaces, tabs, new
                  lines or commas. Input domain without scheme (e.g.
                  domain.com). You can use * mark as placeholder which mean any
                  count of chars (e.g. *.domain.com will allow any subdomains in
                  domain.com).
                </p>
                <textarea
                  class="form-control mb-4"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  placeholder="domain.com domain2.com sub.domain3.com *.domain4.com"
                ></textarea>
                <button type="button" className="btn btn-primary mt-3">
                  Save Domains
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
