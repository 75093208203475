import React, { useState } from "react";
import Layout from "../layout";
import SalesTeamMap from "../components/salesTeammap";
import ModalComp from "../components/modalComp";

import "../assets/css/admin.css";
import {
  IconUsers,
} from "@tabler/icons-react";

export default function SalesTeam() {
  return (
    <Layout>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-2"></h4>
            <div className="row">
              <div className="col-lg-12 mb-4 col-md-12">
                <div className="card h-100">
                  <div className="card-header ">
                    <h5 className="card-title mb-0 fw-bold">Sales Team Overview</h5>
                  </div>
                  <div className="card-body pt-2">
                    <div className="row gy-3">
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">2154</h5>
                            <small>Total Members</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-success me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">1985</h5>
                            <small>Active Users</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-danger me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">59</h5>
                            <small>Inactive Members</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                          </div>
                          <div className="card-info">

                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="row mt-4 gy-3">
                      <div className="col-md col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-info me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">6</h5>
                            <small>Country Managers</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-success me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">6</h5>
                            <small>Regional Managers</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-warning me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">319</h5>
                            <small>Area Developer Fees</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-md col-6">
                        <div className="d-flex align-items-center">
                          <div className="badge rounded-pill bg-label-danger me-3 p-2">
                            <IconUsers />
                          </div>
                          <div className="card-info">
                            <h5 className="mb-0 fw-bold">1879</h5>
                            <small>Sales Reps</small>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg">
                <div className="card h-100 p-3">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title mb-0 fw-bold">Sales Team Map</h5>
                  </div>
                  <SalesTeamMap className="w-50 text-center d-flex justify-content-center" />
                </div>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-lg-12 mb-4 col-md-12">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title mb-0 fw-bold">Add A Member</h5>
                  </div>
                  <div className="card-body pt-2">
                    <ModalComp />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </Layout>
  );
}