import React from 'react'
import SidebarAdmin from './components/sidebarAdmin'
import "./assets/vendor/css/rtl/core.css";
import "./assets/vendor/css/rtl/theme-default.css";

export default function layout(props) {
  return (
    <div className="min-vh-100">
      <div className="layout-page">
        <SidebarAdmin />
        {props?.children}
      </div>
    </div>
  )
}
