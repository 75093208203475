import React from "react";
import ApexChart from 'react-apexcharts';

export default function Donutchart({ data }) {

  const series = Object.values(data);
  const labels = Object.keys(data);

  // const series = [72, 36, 28, 14];
  // const labels = ["Plan 1", "Plan 2", "Plan 3", "Trial"];

  const options = {
    chart: {
      type: "donut",
      width: "100%",
    },
    labels: labels,

    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: false,
            },

            total: {
              show: false,
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ["#FFD700", "#6A5ACD", "#3CB371", "#A9A9A9"], // Colors similar to the image
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div>
      <ApexChart options={options} series={series} type="donut" height={500} />
    </div>
  );
}
