import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ContactModal = ({ show, setShow, onSave }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("🚀 ~ file: contactModal.js:14 ~ onSubmit ~ data:", data);
    onSave(data);
    handleClose();
    reset();
  };

  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="modal-form"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Field</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Field Name:</label>

            <input
              className="mt-2 mb-1"
              type="text"
              {...register("fieldName", { required: true })}
            />
            {errors.fieldName && (
              <small className="text-danger ms-1">Field Name is Required</small>
            )}
          </div>
          <div className="mt-2">
            <label>
              Field Type:
              <select
                class="form-select text-capitalize mt-2"
                {...register("fieldType", { required: true })}
              >
                <option value="text" class="text-capitalize">
                  Text
                </option>
                <option value="number" class="text-capitalize">
                  Number
                </option>
                <option value="email" class="text-capitalize">
                  Email
                </option>
              </select>
            </label>
          </div>
          <div className="mt-4">
            <label>
              Required :
              <select
                class="form-select text-capitalize mt-2"
                {...register("isRequired", { required: true })}
              >
                <option value={false} class="text-capitalize">
                  No
                </option>

                <option value={true} class="text-capitalize">
                  Yes
                </option>
              </select>
            </label>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit(onSubmit)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactModal;
