import { get, post, put, deleted } from '../index';
import SubscriptionRoutes from './Subscription.Routes';

const SubscriptionServices = {
  createSubscription: async (obj) => {
    const data = await post(SubscriptionRoutes.createSubscription, obj);
    return data;
  },
  getSubscriptions: async (country) => {
    const data = await get(SubscriptionRoutes.getSubscriptions + country);
    return data;
  },
  updateSubscription: async (obj) => {
    const data = await put(SubscriptionRoutes.updateSubscription, obj);
    return data;
  },
  deleteSubscription: async (id) => {
    const data = await deleted(SubscriptionRoutes.deleteSubscription + id);
    return data;
  },
}

export default SubscriptionServices