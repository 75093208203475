import { get, post, put, deleted } from '../index';
import FaqRoutes from './Faq.Routes';

const FaqServices = {
  createFaq: async (obj) => {
    const data = await post(FaqRoutes.createFaq, obj);
    return data;
  },
  getFaqs: async (params) => {
    const data = await get(FaqRoutes.getFaqs, params);
    return data;
  },
  updateFaq: async (obj) => {
    const data = await put(FaqRoutes.updateFaq, obj);
    return data;
  },
  deleteFaq: async (id) => {
    const data = await deleted(FaqRoutes.deleteFaq + id);
    return data;
  },
}

export default FaqServices