import { get } from '../index';
import CountryRoutes from './Country.Routes';

const CountryServices = {
  getCountries: async (params) => {
    const data = await get(CountryRoutes.getCountries, params);
    return data;
  },
  getCountriesByPlan: async (params) => {
    const data = await get(CountryRoutes.getCountriesByPlan, params);
    return data;
  },
}

export default CountryServices