import {
  IconChartPie2,
  IconDeviceLaptop,
  IconDotsVertical,
  IconEdit,
  IconEye,
  IconSettings,
  IconTrash,
  IconUser,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DATA from "../assets/json/user-list.json";
const AcessRoleTable = () => {
  const [data, setData] = useState("");

  // for fetching json data
  useEffect(() => {
    setData(DATA.data);
  }, []);
  console.log(data);

  // for handling avator pics
  const avatorHandler = (row) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <img
          src={row.image}
          alt={row.name}
          className="avatorImageCustom"
          style={{ width: "50px", height: "50px", marginRight: "10px" }}
        />
        <div>
          {row.full_name} <br />
          <small className="text-muted">{row.email}</small>
        </div>
      </div>
    );
  };

  //eslit-disable-next-line
  const roleHandler = (row) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {row.role === "Subscriber" ? (
          <IconUser className="bg-label-warning roleIconCustom" />
        ) : row.role === "Author" ? (
          <IconSettings className="bg-label-success roleIconCustom" />
        ) : row.role === "Admin" ? (
          <IconDeviceLaptop className="bg-label-secondary roleIconCustom" />
        ) : row.role === "Editor" ? (
          <IconEdit className="bg-label-info roleIconCustom" />
        ) : (
          <IconChartPie2 className="bg-label-primary_custom roleIconCustom" />
        )}
        {row.role}
      </div>
    );
  };
  const actionIconHandler = () => {
    return (
      <div class="d-flex align-items-center">
        <a href="app-user-view-account.html" class="text-body delete-record">
          <IconEye className="me-3" size={"20px"} />
        </a>
        <a href="javascript:;" class="text-body delete-record">
          <IconTrash size={"20px"} className="me-3" />
        </a>
        <a
          href="javascript:;"
          class="text-body dropdown-toggle hide-arrow"
          data-bs-toggle="dropdown"
        >
          <IconDotsVertical size={"20px"} />
        </a>
        <div class="dropdown-menu dropdown-menu-end m-0">
          <a href="javascript:;" class="dropdown-item">
            Edit
          </a>
          <a href="javascript:;" class="dropdown-item">
            Suspend
          </a>
        </div>
      </div>
    );
  };

  // for columns
  const columns = [
    {
      name: "USER",
      cell: avatorHandler,
      sortable: true,
    },

    {
      name: "ROLE",
      cell: roleHandler,
      sortable: true,
    },
    {
      name: "PLAN",
      selector: (row) => row.current_plan,
      sortable: true,
    },
    {
      name: "BILLING",
      selector: (row) => row.billing,
      sortable: true,
    },

    {
      name: "STATUS",
      cell: (row) => (
        <span
          className={`badge ${
            row.status === 1
              ? "bg-label-warning"
              : row.status === 2
              ? "bg-label-success"
              : "bg-label-secondary"
          } text-capitalized`}
        >
          {row.status === 1
            ? "Pending"
            : row.status === 2
            ? "Active"
            : "Inactive"}
        </span>
      ),
    },
    {
      name: "ACTIONS",
      cell: actionIconHandler,

      sortable: true,
    },
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      pagination
      paginationPerPage={10}
      responsive={true}
      overflowY={true}
      highlightOnHover
      className="secondaryCheckbox "
    />
  );
};

export default AcessRoleTable;
