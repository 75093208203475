import { post, get } from '../index';
import DashboardRoutes from './Dashboard.Routes';

const DashboardServices = {
    dashboard: async (obj = {}) => {
        const data = await get(DashboardRoutes.getDashboardData, obj);
        return data;
    }
}

export default DashboardServices