import moment from "moment";
import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useForm } from "react-hook-form";

export default function Chartboard({ data }) {

  const { register, setValue } = useForm();

  const [months, setMonths] = useState([]);
  const [chartData, setChartData] = useState({ users: [], dates: [] }); // Example data for September

  const handleMonthChange = (value) => {
    const singupDate = []
    const singupUser = []

    if (data) {
      data[value].forEach(e => {
        const date = e.date.split('-')[0]
        const index = singupDate.findIndex(d => d === date)
        if (index === -1) {
          singupDate.push(date)
          singupUser.push(e.user)
        } else {
          singupUser[index] += e.user
        }
      })
      let obj = { users: singupUser, dates: singupDate }
      setChartData(obj)
    }
  }

  const state = {
    series: [{
      name: 'New Signups',
      data: chartData?.users
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false  // Removes the toolbar options like download SVG
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: chartData?.dates,
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 'bold' // Adjust as needed
          }
        },

        title: {
          style: {
            fontSize: '14px',
            fontWeight: 'bold' // Adjust as needed for the axis title
          }
        }
      },
      yaxis: {
        min: 0,
        max: Math.max(...chartData?.users) + 2,
        // max: Object.values(data).length,
        tickAmount: 4,  // Specifies the number of ticks (intervals) for y-axis

        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 'bold' // Adjust as needed
          }
        },

        title: {
          style: {
            fontSize: '14px',
            fontWeight: 'bold' // Adjust as needed for the axis title
          }
        }
      },
      title: {
        text: 'New Signup Chart',
        style: {
          fontSize: '24px',  // Increase this value to make the title bigger
          fontWeight: 'bold'
        }
      },
      theme: {
        monochrome: {
          enabled: true,
          color: '#61d9ca',
        }
      }
    }
  };

  useEffect(() => {
    if (data) {
      const dates = []
      for (const [key, value] of Object.entries(data)) {
        dates.push(key)
      }

      // const month = moment('2023-12', 'YYYY-MM');
      // const daysInMonth = month.daysInMonth();

      const singupDate = []
      const singupUser = []

      data[dates[dates.length - 1]].forEach(e => {
        const date = e.date.split('-')[0]
        const index = singupDate.findIndex(d => d === date)
        if (index === -1) {
          singupDate.push(date)
          singupUser.push(e.user)
        } else {
          singupUser[index] += e.user
        }
      })
      let obj = { users: singupUser, dates: singupDate }
      setChartData(obj)
      setMonths(dates)
    }
  }, [data]);

  useEffect(() => {
    setValue('month', months[months.length - 1])
  }, [months]);

  return (
    <div>
      <div className="text-end">
        <select
          {...register("month", {
            onChange: (e) => handleMonthChange(e.target.value)
          })}
        >
          <option value="" disabled>Select Month</option>
          {months?.map((item, index) => (
            <option key={index} value={item}>{item}</option>
          ))}
        </select>
      </div>
      <div id="chart">
        <ApexCharts
          options={state.options}
          series={state.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
}
