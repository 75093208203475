import {
  IconCreditCard,
  IconMessageDots,
  IconPackage,
  IconSettings,
  IconShoppingCart,
  IconTrash,
  IconUser,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import oneImage from "../assets/img/avatars/1.png";
import "../assets/vendor/css/pages/front-page.css";
import { baseUrl } from "../config/axios";
import ChatbotServices from "../api/chatbot/ChatbotServices";
import { ErrorToaster, SuccessToaster } from "./Toaster";
import Spinner from "./spinner";
import { Fragment } from "react";
import moment from "moment"

export default function RunningChatbot({ loader, data, deleteChatbot }) {

  const Navigate = useNavigate();

  const colourOptions = [
    { value: "", label: "Choose Key Type" },
    { value: "full", label: "Full Control" },
    { value: "modify", label: "Modify" },
    { value: "read-execute", label: "Read Only" }, // added option
    { value: "read-write", label: "PuRead & Writerple" }, // added option
    // ... other colors
  ];

  const [searchedCustomers, setSearchedCustomers] = useState(data);

  const searchChatbot = (value) => {
    if (value) {
      let val = value?.toLowerCase()
      const filteredData = data.filter(e => e?.name?.toLowerCase().includes(val));
      setSearchedCustomers(filteredData)
    } else {
      setSearchedCustomers(data)
    }
  }

  useEffect(() => {
    setSearchedCustomers(data)
  }, [data]);

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1">
        <>
          <h4 className="pt-3 mb-4 fw-bold">Expired Project</h4>
          <div className="d-flex justify-content-between mb-3">
            <div className="flex-grow-1 flex-1">
              <Form.Control type="text" placeholder="Search..." onChange={(e) => searchChatbot(e.target.value)} />
            </div>
            <div className="flex-grow-1 flex-1 d-flex justify-content-end">
              <div className="col-md-3 col-6 me-md-2">
                {/* <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={colourOptions[0]}
                    isSearchable={isSearchable}
                    name="color"
                    options={colourOptions}
                  /> */}
              </div>
              <div className="col-md-3 col-6">
                {/* <div className="gap-2">
                    <DropdownButton
                      id="dropdown-sort"
                      title="Newest First"
                      variant="outline-secondary"
                      className="w-100"
                    >
                      <Dropdown.Item href="#/action-1">Newest First</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Oldest First</Dropdown.Item>
                    </DropdownButton>
                  </div> */}
              </div>
            </div>
          </div>
        </>
        {loader ? (
          <Spinner />
        ) : (
          <Fragment>
            {searchedCustomers?.length > 0 &&
              searchedCustomers?.map((item, index) => (
                <div key={index} className="row">
                  <div className="col-lg-12 col-xxl-12 mb-4 order-xxl-1">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <div className="card-title mb-0 profile-image-main flex-row d-flex gap-2">
                          <div className="profile-image prof-new">
                            <img className="w-100 rounded-circle" src={item?.profilePic ? baseUrl + item?.profilePic : oneImage} />
                          </div>
                          <div>
                            <h5 onClick={() => Navigate(`/admin/profile/${item?.id}`)} className="m-0 me-2 cursor-pointer">{item?.projectName}</h5>
                            <small className="text-muted">
                              Total number of Queries {item?.stats?.data?.total_queries}
                            </small>
                          </div>
                        </div>
                        <div>
                          <span className="mx-2">Last Updated {moment(item?.updatedAt).fromNow()}</span>
                          <IconSettings
                            size={18}
                            className="mx-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => Navigate(`/admin/chatbot-setting/${item?.id}/${item?.projectId}`)}
                          />
                          {item.configuredByAdmin===true&&
                            <button onClick={()=>{deleteChatbot(item.id)}} className="mx-2 text-white button bg-danger border border-none rounded p-2">
                              Deactivate
                            </button>
                          }
                          {item.configuredByAdmin===false&&
                            <button onClick={()=>{deleteChatbot(item.id)}} className="mx-2 text-white button bg-success border border-none rounded p-2">
                              Activate
                            </button>
                          }
                          {/* <IconTrash
                            size={18}
                            className="ml-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteChatbot(item?.id)}
                          /> */}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="container">
                          <hr />
                        </div>

                        <div className="row p-3">
                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-primary rounded-pill p-2">
                                    <IconUser size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{item?.stats?.dashboardstats?.data?.pages_found}</h5>
                                <p>Items Found</p>
                              </div>
                              <div id="subscriberGained"></div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-danger rounded-pill p-2">
                                    <IconShoppingCart size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{item?.stats?.dashboardstats?.data?.pages_indexed}</h5>
                                <p>Items Indexed</p>
                              </div>
                              <div id="quarterlySales"></div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard ">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-warning rounded-pill p-2">
                                    <IconPackage size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{item?.stats?.dashboardstats?.data?.total_words_indexed}</h5>
                                <p>Word Indexed</p>
                              </div>
                              <div id="orderReceived"></div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-6 mb-4">
                            <div className="card border-gray h-100 chatcard">
                              <div className="card-body p-3">
                                <div className="card-icon">
                                  <span className="badge bg-label-success rounded-pill p-2">
                                    <IconCreditCard size={16} />
                                  </span>
                                </div>
                                <h5 className="card-title mb-0 py-2 mt-2">{item?.stats?.dashboardstats?.data?.total_queries}</h5>
                                <div>
                                  <p>Queries this month</p>
                                </div>
                              </div>
                              <div id="revenueGenerated"></div>
                            </div>
                          </div>
                        </div>
                        {/* <button onClick={() => Navigate(`/admin/chat/${item?.sessionId}`)} className="w-100 rounded border m-0 p-3 bg-primary text-white">
                          <IconMessageDots className="mx-2" /> Talk To Kriss AI
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </Fragment>
        )}

      </div>

      <div className="content-backdrop fade"></div>
    </div>
  );
}
