import React from "react";

export default function BillingPlan({ currentPlan }) {

  return (
    <div>
      <div className="card mb-4">
        <h5 className="card-header">Current Plan</h5>
        <div className="card-body">
          <p className="mb-1">Current Plan is {currentPlan?.name}</p>
          <p>{currentPlan?.features?.subtitle}</p>
          <div className="my-3 space-y-2">
            {currentPlan?.features?.keyfeatures.map((item, index) => (
              <div key={index} className="flex items-center" style={{ display: 'flex' }}>
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-green-400 opacity-75 w-5 h-5"
                  style={{ width: '1.5rem', color: 'rgba(52, 211, 153)', marginRight: '6px' }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div className="ml-2 text-sm text-gray-600">
                  {" "}
                  {item}{" "}
                </div>
              </div>
            ))}
          </div>
          <p>USD {currentPlan?.price} / Per month</p>
        </div>
      </div>
    </div >
  );
}
