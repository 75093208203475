import { IconPlus } from "@tabler/icons-react";
import React, { useState } from "react";
import { Button, Col, Form, Modal, ModalBody, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
export default function ModalComp() {
  const [showCountryManagerModal, setShowCountryManagerModal] = useState(false);
  const [showRegionalManagerModal, setShowRegionalManagerModal] =
    useState(false);
  const [showAreaDeveloperModal, setShowAreaDeveloperModal] = useState(false);
  const [showSalesRepModal, setShowSalesRepModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    setShowCountryManagerModal(false);
    reset();
  };

  const countries = ["USA", "Canada", "UK", "Germany", "France", "Australia"];
  const handleOpenModal = (modalName) => {
    if (modalName === "countryManager") {
      setShowCountryManagerModal(true);
    } else if (modalName === "regionalManager") {
      setShowRegionalManagerModal(true);
    } else if (modalName === "areaDeveloper") {
      setShowAreaDeveloperModal(true);
    } else if (modalName === "salesRep") {
      setShowSalesRepModal(true);
    }
  };

  // Handlers to close each modal
  const handleCloseModal = (modalName) => {
    if (modalName === "countryManager") {
      setShowCountryManagerModal(false);
    } else if (modalName === "regionalManager") {
      setShowRegionalManagerModal(false);
    } else if (modalName === "areaDeveloper") {
      setShowAreaDeveloperModal(false);
    } else if (modalName === "salesRep") {
      setShowSalesRepModal(false);
    }
  };
  const managers = [{ name: "John Doe", id: "4532" }];
  const regions = ["East Coast", "West Coast", "Midwest"];

  return (
    <>
      <Row className=" gy-3">
        {["countryManager", "regionalManager", "areaDeveloper", "salesRep"].map(
          (role, index) => (
            <Col xs={12} sm={6} md={2} key={index} className="text-center">
              <div
                variant="light"
                className="badge rounded-pill border border-2 border-primary bg-label-primary p-2 mb-1"
              >
                <IconPlus
                  className="cursor-pointer"
                  onClick={() => handleOpenModal(role)}
                />
              </div>
              <div className="fw-bold">
                {role
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .toLowerCase()
                  .replace(/^\w|\s\w/g, function (letter) {
                    return letter.toUpperCase();
                  })}
              </div>
            </Col>
          )
        )}
      </Row>

      <Modal
        show={showCountryManagerModal}
        onHide={() => handleCloseModal("countryManager")}
        centered
        size="md"
      >
        <Modal.Header closeButton></Modal.Header>
        <h4 className="text-center p-0">Add Country Manager</h4>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Name</Form.Label>
              <Form.Control
                type="text"
                {...register("name", { required: true })}
              />
              {errors.name && <p className="text-danger">Name is required.</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Email</Form.Label>
              <Form.Control
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-danger">Email is required.</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Country</Form.Label>
              <Form.Select
                aria-label="Default select example"
                {...register("country", { required: true })}
              >
                <option value="USA">USA</option>
                <option value="France">France</option>
                <option value="Dubai">Dubai</option>
                <option value="Pakistan">Pakistan</option>
              </Form.Select>
              {errors.country && (
                <p className="text-danger">Country is required.</p>
              )}
            </Form.Group>

            <div className="d-flex align-items-center justify-content-center flex-wrap mb-0 mb-md-4">
              <label className="switch switch-primary ms-3 ms-sm-0 mt-2">
                <span
                  className="switch-label"
                  value={1}
                  {...register("paymentMethod")}
                >
                  Commission
                </span>
                <input
                  type="checkbox"
                  className="switch-input price-duration-toggler"
                />
                <span className="switch-toggle-slider">
                  <span className="switch-on"></span>
                  <span className="switch-off"></span>
                </span>
                <span
                  className="switch-label"
                  value={2}
                  {...register("paymentMethod")}
                >
                  Fixed Salary
                </span>
              </label>
            </div>
            <div className="text-center py-2">
              <Button variant="primary" type="submit">
                Add
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        show={showRegionalManagerModal}
        onHide={() => handleCloseModal("regionalManager")}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Rgional Manager</Modal.Title> */}
        </Modal.Header>
        <h4 className="text-center p-0">Add Regional Manager</h4>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label style={{ fontWeight: "700" }}>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                {...register("name")}
              />
              {errors.name && <p className="text-danger">Name is required.</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Email</Form.Label>
              <Form.Control
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-danger">Email is required.</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Label style={{ fontWeight: "700" }}>Country</Form.Label>
              <Form.Control as="select" {...register("country")}>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicManager">
              <Form.Label style={{ fontWeight: "700" }}>
                Country Manager
              </Form.Label>
              <Form.Control as="select" {...register("manager")}>
                {managers.map((manager, index) => (
                  <option key={index} value={manager.id}>
                    {manager.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicRegion">
              <Form.Label style={{ fontWeight: "700" }}>
                Allocate Region
              </Form.Label>
              <Form.Control as="select" {...register("region")}>
                {regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className="d-flex align-items-center justify-content-center flex-wrap mb-0 mb-md-4">
              <label className="switch switch-primary ms-3 ms-sm-0 mt-2">
                <span
                  className="switch-label"
                  value={1}
                  {...register("paymentMethod")}
                >
                  Commission
                </span>
                <input
                  type="checkbox"
                  className="switch-input price-duration-toggler"
                />
                <span className="switch-toggle-slider">
                  <span className="switch-on"></span>
                  <span className="switch-off"></span>
                </span>
                <span
                  className="switch-label"
                  value={2}
                  {...register("paymentMethod")}
                >
                  Fixed Salary
                </span>
              </label>
            </div>

            <div className="text-center py-2">
              <Button variant="primary" type="submit">
                Add
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Area Developer Modal */}
      <Modal
        show={showAreaDeveloperModal}
        onHide={() => handleCloseModal("areaDeveloper")}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Hi</Modal.Title> */}
        </Modal.Header>
        <h4 className="text-center p-0">Add Area Developer</h4>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label style={{ fontWeight: "700" }}>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                {...register("name")}
              />
              {errors.name && <p className="text-danger">Name is required.</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Email</Form.Label>
              <Form.Control
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-danger">Email is required.</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Label style={{ fontWeight: "700" }}>Country</Form.Label>
              <Form.Control as="select" {...register("country")}>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicManager">
              <Form.Label style={{ fontWeight: "700" }}>
                Country Manager
              </Form.Label>
              <Form.Control as="select" {...register("manager")}>
                {managers.map((manager, index) => (
                  <option key={index} value={manager.id}>
                    {manager.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicManager">
              <Form.Label style={{ fontWeight: "700" }}>
                Regional Manager
              </Form.Label>
              <Form.Control as="select" {...register("manager")}>
                {managers.map((manager, index) => (
                  <option key={index} value={manager.id}>
                    {manager.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicRegion">
              <Form.Label style={{ fontWeight: "700" }}>Allocate</Form.Label>
              <Form.Control as="select" {...register("region")}>
                {regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className="d-flex align-items-center justify-content-center flex-wrap mb-0 mb-md-4">
              <label className="switch switch-primary ms-3 ms-sm-0 mt-2">
                <span
                  className="switch-label"
                  value={1}
                  {...register("paymentMethod")}
                >
                  Commission
                </span>
                <input
                  type="checkbox"
                  className="switch-input price-duration-toggler"
                />
                <span className="switch-toggle-slider">
                  <span className="switch-on"></span>
                  <span className="switch-off"></span>
                </span>
                <span
                  className="switch-label"
                  value={2}
                  {...register("paymentMethod")}
                >
                  Fixed Salary
                </span>
              </label>
            </div>

            <div className="text-center py-2">
              <Button variant="primary" type="submit">
                Add
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Sales Rep Modal */}
      <Modal
        show={showSalesRepModal}
        onHide={() => handleCloseModal("salesRep")}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Hi</Modal.Title> */}
        </Modal.Header>
        <h4 className="text-center p-0">Add Sales Rep</h4>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label style={{ fontWeight: "700" }}>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                {...register("name")}
              />
              {errors.name && <p className="text-danger">Name is required.</p>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Email</Form.Label>
              <Form.Control
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="text-danger">Email is required.</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCountry">
              <Form.Label style={{ fontWeight: "700" }}>Country</Form.Label>
              <Form.Control as="select" {...register("country")}>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicManager">
              <Form.Label style={{ fontWeight: "700" }}>
                Country Manager
              </Form.Label>
              <Form.Control as="select" {...register("manager")}>
                {managers.map((manager, index) => (
                  <option key={index} value={manager.id}>
                    {manager.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicManager">
              <Form.Label style={{ fontWeight: "700" }}>
                Regional Manager
              </Form.Label>
              <Form.Control as="select" {...register("manager")}>
                {managers.map((manager, index) => (
                  <option key={index} value={manager.id}>
                    {manager.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicRegion">
              <Form.Label style={{ fontWeight: "700" }}>
                Area Developer
              </Form.Label>
              <Form.Control as="select" {...register("region")}>
                {regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className="d-flex align-items-center justify-content-center flex-wrap mb-0 mb-md-4">
              <label className="switch switch-primary ms-3 ms-sm-0 mt-2">
                <span
                  className="switch-label"
                  value={1}
                  {...register("paymentMethod")}
                >
                  Commission
                </span>
                <input
                  type="checkbox"
                  className="switch-input price-duration-toggler"
                />
                <span className="switch-toggle-slider">
                  <span className="switch-on"></span>
                  <span className="switch-off"></span>
                </span>
                <span
                  className="switch-label"
                  value={2}
                  {...register("paymentMethod")}
                >
                  Fixed Salary
                </span>
              </label>
            </div>

            <div className="text-center py-2">
              <Button variant="primary" type="submit">
                Add
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
