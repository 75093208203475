import { post } from '../index';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  changePassword: async (obj) => {
    const data = await post(AuthRoutes.changePassword, obj);
    return data;
  },
}

export default AuthServices