import { post, get } from '../index';
import ConversationRoutes from './Conversation.Routes';

const ConversationServices = {
  createConversation: async (obj) => {
    const data = await post(ConversationRoutes.createConversation, obj);
    return data;
  },
  getMessages: async (params) => {
    const data = await get(ConversationRoutes.getMessages, params);
    return data;
  },
  sendMessage: async (obj) => {
    const data = await post(ConversationRoutes.sendMessage, obj);
    return data;
  },
  getConversationList: async (params) => {
    const data = await get(ConversationRoutes.getConversationList, params);
    return data;
  },
}

export default ConversationServices