import React from "react";
import "../assets/css/admin.css";
import Pricingpckg from "../components/pricingpckg";
import Layout from "../layout";

export default function AdmPlan() {
  return (
    <Layout>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y responsive-width-control">
          <div className="card p-4 mt-3">
            <Pricingpckg />
          </div>
        </div>
      </div>
    </Layout>
  );
}
