import { get, post, put, deleted } from '../index';
import CouponRoutes from './Coupon.Routes';

const CouponServices = {
  createCoupon: async (obj) => {
    const data = await post(CouponRoutes.createCoupon, obj);
    return data;
  },
  getCoupons: async () => {
    const data = await get(CouponRoutes.getCoupons);
    return data;
  },
  updateCoupon: async (obj) => {
    const data = await put(CouponRoutes.updateCoupon, obj);
    return data;
  },
  deleteCoupon: async (id) => {
    const data = await deleted(CouponRoutes.deleteCoupon + id);
    return data;
  },
}

export default CouponServices