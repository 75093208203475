import { IconEdit, IconSearch, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import CountryServices from "../api/country/CountryServices";
import SubscriptionServices from "../api/subscription/SubscriptionService";
import "../assets/css/admin.css";
import "../assets/vendor/css/pages/front-page.css";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import { ErrorToaster, SuccessToaster } from "./Toaster";
import Spinner from "./spinner";

export default function Pricingpckg() {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const [loader, setLoader] = useState(false);

  const handleClose = (edit) => {
    edit ? setEditShow(false) : setShow(false);
    reset();
    reset2();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
    setValue: setValue2,
  } = useForm();

  const [plans, setPlans] = useState([]);
  const [searchPlans, setSearchPlans] = useState([]);
  const [planId, setPlanId] = useState();

  const [filter, setFilter] = useState("");

  const [countries, setCountries] = useState([]);
  const [planCountries, setPlanCountries] = useState([]);

  const getSubscriptionPlans = async (country) => {
    setLoader(true);
    try {
      const Filter = country ?? filter;
      setFilter(Filter);
      const { data } = await SubscriptionServices.getSubscriptions(Filter);
      setPlans(data);
      setSearchPlans(data);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const searchSubscriptionPlans = (value) => {
    if (value) {
      let val = value?.toLowerCase();
      const filteredData = plans.filter((e) =>
        e?.name?.toLowerCase().includes(val)
      );
      setSearchPlans(filteredData);
    } else {
      setSearchPlans(plans);
    }
  };

  const handleOpenEditModal = (data) => {
    setEditShow(true);
    setPlanId(data?.id);
    setValue2("planName", data?.name);
    setValue2("price", data?.price);
    setValue2("setupFee", data?.setupFees);
    setValue2("queryCount", data?.queryCount);
    setValue2("subtitle", data?.features?.subtitle);
    setValue2("country", data?.country);
    setValue2("features", data?.features?.keyfeatures.join(","));
  };

  const createSubscription = async (formData) => {
    setLoader(true);
    try {
      let obj = {
        name: formData?.planName,
        price: formData?.price,
        setupFees: formData?.setupFee,
        queryCount: formData?.queryCount,
        country: formData?.country,
        features: {
          subtitle: formData?.subtitle,
          keyfeatures: formData?.features.split(","),
        },
      };
      const { message } = await SubscriptionServices.createSubscription(obj);
      SuccessToaster(message);
      getSubscriptionPlans();
      handleClose();
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const updateSubscription = async (formData) => {
    setLoader(true);
    try {
      let obj = {
        id: planId,
        name: formData?.planName,
        price: formData?.price,
        setupFees: formData?.setupFee,
        queryCount: formData?.queryCount,
        country: formData?.country,
        features: {
          subtitle: formData?.subtitle,
          keyfeatures: formData?.features.split(","),
        },
      };
      const { message } = await SubscriptionServices.updateSubscription(obj);
      SuccessToaster(message);
      getSubscriptionPlans();
      handleClose(true);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const deleteSubscription = async (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this Plan?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#FF0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { message } = await SubscriptionServices.deleteSubscription(id);
        SuccessToaster(message);
        getSubscriptionPlans();
      }
    });
  };

  const getCountries = async () => {
    try {
      const { data } = await CountryServices.getCountries();
      setCountries(data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const getCountriesByPlan = async () => {
    try {
      const { data } = await CountryServices.getCountriesByPlan();
      setPlanCountries(data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const FeaturesCell = (row) => (
    <>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={row?.features?.keyfeatures.join(",")}
      >
        {row?.features?.keyfeatures.join(", ")}
      </div>
    </>
  );

  const ActionButtons = (row) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingRight: "30px",
      }}
    >
      <IconEdit
        size={20}
        style={{ cursor: "pointer" }}
        className="icon-style"
        onClick={() => handleOpenEditModal(row)}
      />
      <IconTrash
        size={20}
        style={{ cursor: "pointer" }}
        className="icon-style"
        onClick={() => deleteSubscription(row?.id)}
      />
    </div>
  );

  // columns for react-data-tables
  const columns = [
    {
      name: "Subscription Plans",
      selector: (row) => row.name,
    },

    {
      name: "Features",
      cell: FeaturesCell,
      sortable: true,
      width: "300px",
    },
    {
      name: "Query",
      selector: (row) => `${row.queryCount}`,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Setup Fees",
      selector: (row) => row.setupFees,
      sortable: true,
    },
    {
      name: "Actions",
      cell: ActionButtons,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    getCountriesByPlan();
    getCountries();
    getSubscriptionPlans();
  }, []);

  return (
    <>
      <h2 className="text-center mb-2 fw-bold">Pricing Plans</h2>
      <p className="text-center">
        Get started with us - it's perfect for individuals and teams. Choose a
        subscription plan that meets your needs.
      </p>
      {/* <div className="d-flex align-items-center justify-content-center flex-wrap gap-2 pb-5 pt-3 mb-0 mb-md-4">
        <label
          className="switch switch-primary ms-3 ms-sm-0 mt-2"
          onClick={handleToggle}
        >
          <span className="switch-label">Monthly</span>
          <input
            type="checkbox"
            className="switch-input price-duration-toggler"
            checked={isChecked}
            onChange={handleToggle}
          />
          <span className="switch-toggle-slider">
            <span className="switch-on"></span>
            <span className="switch-off"></span>
          </span>
          <span className="switch-label">Annual</span>
        </label>
        <div className="mt-n5 ms-n5 d-none d-sm-block">
          <IconCornerLeftDown />
          <span className="badge badge-sm bg-label-primary">
            Save up to 10%
          </span>
        </div>
      </div> */}

      <div className="container">
        <div className="row">
          <div class="col-3">
            <div class="input-group input-group-merge">
              <span class="input-group-text" id="basic-addon-search31">
                <IconSearch />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                aria-label="Search..."
                aria-describedby="basic-addon-search31"
                onChange={(e) => searchSubscriptionPlans(e.target.value)}
              />
            </div>
          </div>
          <div class="col-3">
            <select
              id="multiStepsState"
              className="select2 form-select"
              data-allow-clear="true"
              onChange={(e) => getSubscriptionPlans(e.target.value)}
            >
              <option value=""> Select Country </option>
              {planCountries?.map((item, index) => (
                <option key={index} value={item?.name}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <div class="col-4"></div>
          <div class="col-2">
            <button
              type="button"
              class="btn btn-warning"
              onClick={() => setShow(true)}
            >
              Add New
            </button>
          </div>
        </div>
      </div>

      <div class="card-datatable table-responsive">
        {loader ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={searchPlans}
            pagination
            paginationPerPage={10}
            responsive={true}
            overflowY={true}
            highlightOnHover
            className="secondaryCheckbox"
          />
        )}
      </div>

      {/* add modal */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="modal-form custom-modal-form-style"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(createSubscription)}>
            <div>
              <label>Plan Name:</label>
              <input
                type="text"
                className="mb-1"
                {...register("planName", {
                  required: "Plan Name is Required",
                })}
              />
              {errors.planName && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.planName.message}
                </small>
              )}
            </div>
            <div className="row mt-3">
              <div className="col">
                <label className="mb-1">Price:</label>
                <input
                  type="number"
                  className="mb-1"
                  {...register("price", {
                    required: "Price is Required",
                  })}
                />
                {errors.price && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.price.message}
                  </small>
                )}
              </div>
              <div className="col">
                <label className="mb-1">Setup Fee:</label>
                <input
                  type="number"
                  className="mb-1"
                  {...register("setupFee", {
                    required: "Setup fee is Required",
                  })}
                />
                {errors.setupFee && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.setupFee.message}
                  </small>
                )}
              </div>

              <div className="col">
                <label className="mb-1">Query Count:</label>

                <input
                  type="number"
                  className="mb-1"
                  {...register("queryCount", {
                    required: "Query Count is Required",
                  })}
                />
                {errors.queryCount && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.queryCount.message}
                  </small>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <label className="mb-1">Subtitle:</label>
                <input
                  type="text"
                  className="mb-1"
                  {...register("subtitle", {
                    required: "Subtitle is Required",
                  })}
                />
                {errors.subtitle && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.subtitle.message}
                  </small>
                )}
              </div>

              <div className="col">
                <label className="form-label" for="multiStepsState">
                  Country
                </label>
                <select
                  id="multiStepsState"
                  className="select2 form-select"
                  data-allow-clear="true"
                  {...register("country")}
                >
                  <option value="">Select Country</option>
                  {countries?.map((item, index) => (
                    <option key={index} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors.country.message}
                  </small>
                )}
              </div>
            </div>
            <div className="mt-3">
              <label className="mb-1">Key Feature:</label>
              <textarea
                {...register("features", {
                  required: "Features is Required",
                })}
              />
              {errors.features && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors.features.message}
                </small>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/*pass false for opeinig add confirmation Modal*/}
          <Button variant="primary" onClick={handleSubmit(createSubscription)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <Modal
        show={editShow}
        onHide={() => handleClose(true)} // passing true because we want to close editModal
        size="lg"
        centered
        className="modal-form custom-modal-form-style"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2(updateSubscription)}>
            <div>
              <label>Plan Name:</label>
              <input
                type="text"
                className="mb-1"
                {...register2("planName", {
                  required: "Plan Name is Required",
                })}
              />
              {errors2.planName && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors2.planName.message}
                </small>
              )}
            </div>
            <div className="row mt-3">
              <div className="col">
                <label className="mb-1">Price:</label>
                <input
                  type="number"
                  className="mb-1"
                  {...register2("price", {
                    required: "Price is Required",
                  })}
                />
                {errors2.price && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors2.price.message}
                  </small>
                )}
              </div>
              <div className="col">
                <label className="mb-1">Setup Fee:</label>
                <input
                  type="number"
                  className="mb-1"
                  {...register2("setupFee", {
                    required: "Setup Fee is Required",
                  })}
                />
                {errors2.setupFee && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors2.setupFee.message}
                  </small>
                )}
              </div>

              <div className="col">
                <label className="mb-1">Query Count:</label>

                <input
                  type="number"
                  className="mb-1"
                  {...register2("queryCount", {
                    required: "Query Count is Required",
                  })}
                />
                {errors2.queryCount && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors2.queryCount.message}
                  </small>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <label className="mb-1">Subtitle:</label>
                <input
                  type="text"
                  className="mb-1"
                  {...register2("subtitle", {
                    required: "Subtitle is Required",
                  })}
                />
                {errors2.subtitle && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors2.subtitle.message}
                  </small>
                )}
              </div>

              <div className="col">
                <label className="form-label" for="multiStepsState">
                  Country
                </label>
                <select
                  id="multiStepsState"
                  className="select2 form-select"
                  data-allow-clear="true"
                  {...register2("country")}
                >
                  <option value=""> Select Country </option>
                  {countries?.map((item, index) => (
                    <option key={index} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
                {errors2.country && (
                  <small className="d-block text-danger ms-1 mt-1 ">
                    {errors2.country.message}
                  </small>
                )}
              </div>
            </div>
            <div className="mt-3">
              <label className="mb-1">Key Feature:</label>
              <textarea
                {...register2("features", {
                  required: "Features is Required",
                })}
              />
              {errors2.features && (
                <small className="d-block text-danger ms-1 mt-1 ">
                  {errors2.features.message}
                </small>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit2(updateSubscription)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
