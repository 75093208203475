import React, { Fragment, useEffect, useState } from "react";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import "../assets/vendor/css/pages/app-chat.css";
import img1 from "../assets/img/avatars/1.png";
import img2 from "../assets/img/avatars/2.png";
import Layout from "../layout";
import { IconChecks, IconPlus } from "@tabler/icons-react";
import { IconSend } from "@tabler/icons-react";
import ConversationServices from "../api/conversation/ConversationServices";
import { ErrorToaster } from "../components/Toaster";
import { useForm } from "react-hook-form";
import moment from "moment";
import { baseUrl } from "../config/axios";
import { useParams } from "react-router-dom";
import Spinner from "../components/spinner";
import CustomerServices from "../api/customer/CustomerService";
import ChatbotServices from "../api/chatbot/ChatbotServices";
import LoadingGif from "../assets/img/icons/loadinggif.gif";

export default function Chat(props) {

  const { id } = useParams();

  const { register, handleSubmit, reset } = useForm()

  const [messages, setMessages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const [customerDetail, setCustomerDetail] = useState();
  const [chatbotSetting, setChatbotSetting] = useState();

  const [conversationList, setConversationList] = useState();
  const [activeContact, setActiveContact] = useState(null);

  const [selectedSessionId, setSelectedSessionId] = useState();
  const [isNewChat, setIsNewChat] = useState(false);

  const getCustomerDetail = async () => {
    setLoading(true)
    try {
      const { result } = await CustomerServices.getCustomerDetail(id)
      setCustomerDetail(result)
      getChatbotSetting(result?.projectId)
      getConversationList(result?.projectId)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  const getConversationList = async (id) => {
    setLoading(true)
    try {
      let params = {
        projectId: id,
      };
      const { data } = await ConversationServices.getConversationList(params);
      setConversationList(data?.data);
      setActiveContact(data?.data[0]?.id)
      getMessages(id, data?.data[0]?.session_id);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false)
    }
  };

  const createConversation = async (name) => {
    try {
      let obj = {
        projectId: customerDetail?.projectId,
        data: {
          name: name
        }
      }
      const { data } = await ConversationServices.createConversation(obj)
      const oldConversation = [...conversationList]
      oldConversation.unshift(data)
      setConversationList(oldConversation)
      setActiveContact(data?.id)
      return data?.session_id
    } catch (error) {
      console.log('file: chat.js:60 => createConversation => error:', error)
    }
  }

  const getMessages = async (projectId, sessionId) => {
    setMessageLoading(true)
    try {
      let params = {
        projectId: projectId ? projectId : customerDetail?.projectId,
        sessionId: sessionId,
        page: 1,
        order: "desc",
      };
      const { data } = await ConversationServices.getMessages(params);
      let oldMessages = [];
      for (let i = data?.messages?.data.length - 1; i >= 0; i--) {
        const e = data?.messages?.data[i];
        oldMessages.push({ message: e?.user_query, time: e?.created_at });
        oldMessages.push({ message: e?.openai_response, time: e?.created_at });
      }
      setMessages(oldMessages.reverse());
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setMessageLoading(false)
    }
  };

  const sendMessage = async (formData) => {
    const oldMessages1 = [...messages]
    oldMessages1.unshift({ message: formData?.message, time: moment() });
    oldMessages1.unshift({ loading: true });
    reset()
    setMessages(oldMessages1)
    setSendLoading(true)
    try {
      let sessionId
      if (isNewChat) {
        sessionId = await createConversation(formData?.message)
      }
      let obj = {
        projectId: customerDetail?.projectId,
        sessionId: sessionId ? sessionId : selectedSessionId,
        prompt: formData?.message,
        custom_persona: customerDetail?.name,
      }
      const { data } = await ConversationServices.sendMessage(obj)
      const oldMessages = [...oldMessages1];
      if (data?.data) {
        oldMessages.splice(0, 1, {
          message: data?.data?.openai_response,
          time: data?.data?.created_at,
        })
        setMessages(oldMessages);
      } else {
        oldMessages.splice(0, 1, {
          message: 'Please wait your chatbot is under maintenance',
          time: new Date(),
        })
        setMessages(oldMessages);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setSendLoading(false);
      setIsNewChat(false)
    }
  }

  const getChatbotSetting = async (id) => {
    try {
      let params = {
        projectId: id,
      };
      const { data } = await ChatbotServices.getChatbotSetting(params);
      setChatbotSetting(data);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const newChat = () => {
    setIsNewChat(true)
    setActiveContact()
    setSelectedSessionId()
    setMessages([])
  }

  const changeChat = (data) => {
    try {
      setActiveContact(data?.id);
      setSelectedSessionId(data?.session_id);
      getMessages('', data?.session_id)
    } catch (error) {
      console.log('file: chat.js:123 => changeChat => error:', error)
    }
  };

  useEffect(() => {
    getCustomerDetail()
  }, []);

  return (
    <>
      <Layout>
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="app-chat card overflow-hidden mt-4">
                <div className="row g-0">
                  {/* <!-- Chat & Contacts --> */}
                  <div
                    class="col app-chat-contacts app-sidebar flex-grow-0 overflow-hidden border-end"
                    id="app-chat-contacts"
                  >
                    <div class="sidebar-header">
                      <div class="d-flex align-items-center me-3 me-lg-0">
                        <button
                          onClick={() => newChat()}
                          type="text"
                          class="form-control chat-search-input text-primary border-primary"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search31"
                        >
                          <IconPlus
                            className="text-primary me-1"
                            size={"16px"}
                            style={{ verticalAlign: "middle" }}
                          />
                          New Conversation
                        </button>
                      </div>
                      <i
                        class="ti ti-x cursor-pointer d-lg-none d-block position-absolute mt-2 me-1 top-0 end-0"
                        data-overlay
                        data-bs-toggle="sidebar"
                        data-target="#app-chat-contacts"
                      ></i>
                    </div>
                    <hr class="container-m-nx m-0" />
                    <div class="sidebar-body">
                      <div class="chat-contact-list-item-title">
                        <h5 class="text-primary mb-0 px-4 pt-3 pb-2">
                          Chats
                        </h5>

                        {/* <!-- Chats --> */}
                        {loading ? (
                          <Spinner size='large' />
                        ) : (
                          <ul
                            class="list-unstyled chat-contact-list"
                            id="chat-list"
                          >
                            {/* <li class="chat-contact-list-item chat-list-item-0 d-none">
                              <h6 class="text-muted mb-0">No Chats Found</h6>
                            </li> */}
                            {/* <li
                              className={`chat-contact-list-item  ${activeContact === 1 ? "bg-primary" : ""
                                }`}
                              onClick={() => handleContactClick(1)} // integrate contact id instead of hardcode id
                            >
                              <a class="d-flex align-items-center">
                                <div class="flex-shrink-0 avatar">
                                  <img
                                    src="../../assets/img/avatars/13.png"
                                    alt="Avatar"
                                    class="rounded-circle"
                                  />
                                </div>
                                <div class="chat-contact-info flex-grow-1 ms-2">
                                  <h6 class="chat-contact-name text-truncate m-0">
                                    Waldemar Mannering
                                  </h6>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p class="chat-contact-status text-muted text-truncate mb-0 w-50">
                                      Refer dffrfr
                                    </p>
                                    {activeContact === 1 && (
                                      <span className="d-flex gap-2 text-white">
                                        <IconFileUpload size={"19px"} />
                                        <IconEdit size={"19px"} />
                                        <IconTrash size={"19px"} />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li
                              className={`chat-contact-list-item ${activeContact === 2 ? "bg-primary" : ""
                                }`}
                              onClick={() => handleContactClick(2)} // integrate contact id instead of hardcode id
                            >
                              <a class="d-flex align-items-center">
                                <div class="flex-shrink-0 avatar avatar-offline">
                                  <img
                                    src="../../assets/img/avatars/2.png"
                                    alt="Avatar"
                                    class="rounded-circle"
                                  />
                                </div>
                                <div class="chat-contact-info flex-grow-1 ms-2">
                                  <h6 class="chat-contact-name text-truncate m-0">
                                    Felecia Rower
                                  </h6>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p class="chat-contact-status text-muted text-truncate mb-0 w-50">
                                      I will purchase it for sure. 👍
                                    </p>
                                    {activeContact === 2 && (
                                      <span className="d-flex gap-2 text-white">
                                        <IconFileUpload size={"19px"} />
                                        <IconEdit size={"19px"} />
                                        <IconTrash size={"19px"} />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </a>
                            </li> */}
                            {conversationList?.map((item, index) => (
                              <li
                                className={`chat-contact-list-item ${activeContact === item?.id ? "bg-primary" : ""
                                  }`}
                                onClick={() => changeChat(item)}
                              >
                                <a className="d-flex align-items-center">
                                  <div className="flex-shrink-0 avatar">
                                    <span className="avatar-initial rounded-circle bg-label-success">
                                      CM
                                    </span>
                                  </div>
                                  <div className="chat-contact-info flex-grow-1 ms-2">
                                    <h6 className="chat-contact-name text-truncate m-0">
                                      {item?.name}
                                    </h6>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      {/* <p className="chat-contact-status text-muted text-truncate mb-0 w-50">
                                          If it takes long you can mail inbox user
                                        </p> */}
                                      {/* <span className="d-flex gap-2 text-white">
                                            <IconFileUpload size={"19px"} />
                                            <IconEdit size={"19px"} />
                                            <IconTrash size={"19px"} />
                                          </span> */}
                                    </div>
                                  </div>
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <!-- Chats --> */}

                  <div
                    className="col app-chat-sidebar-left app-sidebar overflow-hidden"
                    id="app-chat-sidebar-left "
                  >
                    <div className=" mt-3 chat-sidebar-left-user sidebar-header d-flex flex-column justify-content-center align-items-center flex-wrap px-4 pt-5">
                      <div className="avatar avatar-xl">
                        <img
                          src={
                            chatbotSetting?.chatbot_avatar
                              ? chatbotSetting?.chatbot_avatar
                              : img2
                          }
                          alt="Avatar"
                          className="rounded-circle"
                        />
                      </div>
                      <h5 className="mt-2 mb-0">KrissAi</h5>
                      {/* <span>Admin</span> */}
                      <i
                        className="ti ti-x ti-sm cursor-pointer close-sidebar"
                        data-bs-toggle="sidebar"
                        data-overlay
                        data-target="#app-chat-sidebar-left"
                      ></i>
                    </div>
                  </div>

                  <div className="col app-chat-history bg-body">
                    <div className="chat-history-wrapper">
                      <div className="chat-history-header border-bottom">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex overflow-hidden align-items-center">
                            <i
                              className="ti ti-menu-2 ti-sm cursor-pointer d-lg-none d-block me-2"
                              data-bs-toggle="sidebar"
                              data-overlay
                              data-target="#app-chat-contacts"
                            ></i>
                            <div className="flex-shrink-0 avatar">
                              <img
                                src={
                                  chatbotSetting?.chatbot_avatar
                                    ? chatbotSetting?.chatbot_avatar
                                    : img2
                                }
                                alt="Avatar"
                                className="rounded-circle"
                                data-bs-toggle="sidebar"
                                data-overlay
                                data-target="#app-chat-sidebar-right"
                              />
                            </div>
                            <div className="chat-contact-info flex-grow-1 ms-2">
                              <h6 className="m-0">KrissAi</h6>
                              {/* <small className="user-status text-muted">
                                NextJS developer
                              </small> */}
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            {/* <IconPhoneCall className="cursor-pointer d-sm-block d-none me-3" />
                            <IconVideo className="cursor-pointer d-sm-block d-none me-3" />
                            <IconSearch className="cursor-pointer d-sm-block d-none me-3" />
                            <div className="dropdown d-flex align-self-center">
                              <button
                                className="btn p-0"
                                type="button"
                                id="chat-header-actions"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="ti ti-dots-vertical"></i>
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="chat-header-actions"
                              >
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  View Contact
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Mute Notifications
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Block Contact
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Clear Chat
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Report
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="chat-history-body bg-body">
                        {messageLoading ? (
                          <Spinner size='large' />
                        ) : (
                          <ul className="list-unstyled chat-history" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            {messages.length > 0 && (
                              <Fragment>
                                {messages.map((item, index) => (
                                  <Fragment key={index}>
                                    {index % 2 !== 0 ? (
                                      <li className="chat-message chat-message-right">
                                        <div className="d-flex overflow-hidden">
                                          <div className="chat-message-wrapper flex-grow-1">
                                            <div className="chat-message-text">
                                              <p className="mb-0">
                                                {item.message}
                                              </p>
                                            </div>
                                            <div className="text-end text-muted mt-1">
                                              <IconChecks
                                                className="me-1 text-success"
                                                size={15}
                                              />
                                              <small>
                                                {moment(item.time).format(
                                                  "DD.MM.YYYY HH:mm a"
                                                )}
                                              </small>
                                            </div>
                                          </div>
                                          <div className="user-avatar flex-shrink-0 ms-3">
                                            <div className="avatar avatar-sm">
                                              <img
                                                src={
                                                  customerDetail?.profilePic
                                                    ? baseUrl + customerDetail?.profilePic
                                                    : img1
                                                }
                                                alt="Avatar"
                                                className="rounded-circle"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ) : (
                                      <li className="chat-message">
                                        <div className="d-flex overflow-hidden">
                                          <div className="user-avatar flex-shrink-0 me-3">
                                            <div className="avatar avatar-sm">
                                              <img
                                                src={
                                                  chatbotSetting?.chatbot_avatar
                                                    ? chatbotSetting?.chatbot_avatar
                                                    : img2
                                                }
                                                alt="Avatar"
                                                className="rounded-circle"
                                              />
                                            </div>
                                          </div>
                                          <div className="chat-message-wrapper flex-grow-1">
                                            {item?.loading ? (
                                              <img
                                                src={LoadingGif}
                                                alt="loading"
                                                style={{ width: '70px' }}
                                              />
                                            ) : (
                                              <div className="chat-message-text">
                                                <p className="mb-0">
                                                  {item?.message}
                                                </p>
                                              </div>
                                            )}
                                            {!item?.loading &&
                                              <div className="text-muted mt-1">
                                                <small>
                                                  {moment(item.time).format(
                                                    "DD.MM.YYYY HH:mm a"
                                                  )}
                                                </small>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            )}
                          </ul>
                        )}
                      </div>

                      <div className="chat-history-footer shadow-sm">
                        <form className="form-send-message d-flex justify-content-between align-items-center">
                          <input
                            className="form-control message-input border-0 me-3 shadow-none"
                            placeholder="Type your message here"
                            {...register("message")}
                          />
                          <div className="message-actions d-flex align-items-center">
                            {/* <IconMicrophone className="speech-to-text cursor-pointer" size={20} />
                            <label for="attach-doc" className="form-label mb-0">
                              <IconPhoto className=" cursor-pointer mx-3" />
                              <input type="file" id="attach-doc" hidden />
                            </label> */}
                            <button
                              disabled={sendLoading}
                              onClick={handleSubmit(sendMessage)}
                              className="btn btn-primary d-flex send-msg-btn"
                            >
                              <IconSend className="me-md-1 me-0" />
                              <span className="align-middle d-md-inline-block d-none">
                                Send
                              </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col app-chat-sidebar-right app-sidebar overflow-hidden"
                    id="app-chat-sidebar-right"
                  >
                    <div className="sidebar-header d-flex flex-column justify-content-center align-items-center flex-wrap px-4 pt-5">
                      <div className="avatar avatar-xl">
                        <img
                          src="../../assets/img/avatars/2.png"
                          alt="Avatar"
                          className="rounded-circle"
                        />
                      </div>
                      <h6 className="mt-2 mb-0">Felecia Rower</h6>
                      <span>NextJS Developer</span>
                      <i
                        className="ti ti-x ti-sm cursor-pointer close-sidebar d-block"
                        data-bs-toggle="sidebar"
                        data-overlay
                        data-target="#app-chat-sidebar-right"
                      ></i>
                    </div>
                    <div className="sidebar-body px-4 pb-4">
                      <div className="my-4">
                        <small className="text-muted text-uppercase">
                          About
                        </small>
                        <p className="mb-0 mt-3">
                          A Next. js developer is a software developer who
                          uses the Next. js framework alongside ReactJS to
                          build web applications.
                        </p>
                      </div>
                      <div className="my-4">
                        <small className="text-muted text-uppercase">
                          Business Information
                        </small>
                        <ul className="list-unstyled d-grid gap-2 mt-3">
                          <li className="d-flex align-items-center">
                            <i className="ti ti-mail ti-sm"></i>
                            <span className="align-middle ms-2">
                              josephGreen@email.com
                            </span>
                          </li>
                          <li className="d-flex align-items-center">
                            <i className="ti ti-phone-call ti-sm"></i>
                            <span className="align-middle ms-2">
                              +1(123) 456 - 7890
                            </span>
                          </li>
                          <li className="d-flex align-items-center">
                            <i className="ti ti-clock ti-sm"></i>
                            <span className="align-middle ms-2">
                              Mon - Fri 10AM - 8PM
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4">
                        <small className="text-muted text-uppercase">
                          Options
                        </small>
                        <ul className="list-unstyled d-grid gap-2 mt-3">
                          <li className="cursor-pointer d-flex align-items-center">
                            <i className="ti ti-badge ti-sm"></i>
                            <span className="align-middle ms-2">Add Tag</span>
                          </li>
                          <li className="cursor-pointer d-flex align-items-center">
                            <i className="ti ti-star ti-sm"></i>
                            <span className="align-middle ms-2">
                              Important Contact
                            </span>
                          </li>
                          <li className="cursor-pointer d-flex align-items-center">
                            <i className="ti ti-photo ti-sm"></i>
                            <span className="align-middle ms-2">
                              Shared Media
                            </span>
                          </li>
                          <li className="cursor-pointer d-flex align-items-center">
                            <i className="ti ti-trash ti-sm"></i>
                            <span className="align-middle ms-2">
                              Delete Contact
                            </span>
                          </li>
                          <li className="cursor-pointer d-flex align-items-center">
                            <i className="ti ti-ban ti-sm"></i>
                            <span className="align-middle ms-2">
                              Block Contact
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="app-overlay"></div>
                </div>
              </div>
            </div>
            <div className="content-backdrop fade"></div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
        <div className="drag-target"></div>
      </Layout>
    </>
  );
}