import React from "react";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

// admin routes
import { ToasterContainer } from "./components/Toaster";
import { useAuth } from "./context/UseContext";
import AcessRole from "./pages/acessRole";
import AdmLogin from "./pages/admLogin";
import AdminPlan from "./pages/admPlan";
import CouponPage from "./pages/coupons";
import AllCustomers from "./pages/allCustomers";
import Chat from "./pages/chat";
import Chatbot from "./pages/chatbot";
import ChatBotSetting from "./pages/chatbotSetting";
import Contact from "./pages/contact";
import DashboardAdmin from "./pages/dashboardAdmin";
import Faq from "./pages/faq";
import Onboardhome from "./pages/onboardThanks";
import Payment from "./pages/payment";
import Profile from "./pages/profile";
import SalesTeam from "./pages/salesTeam";
import ChangePassword from "./pages/changePassword";

export default function App() {
  const { user } = useAuth();

  return (
    <Router>
      <ToasterContainer />

      <Routes>
        <Route
          element={
            user?.token ? <Navigate to="/admin/dashboard" /> : <Outlet />
          }
        >
          <Route path="/" element={<AdmLogin />} />
        </Route>

        <Route element={user?.token ? <Outlet /> : <Navigate to="/" />}>
          <Route path="/admin/dashboard" element={<DashboardAdmin />} />
          <Route path="/admin/chatbot" element={<Chatbot />} />
          <Route path="/admin/subscription-plans" element={<AdminPlan />} />
          <Route path="/admin/coupons" element={<CouponPage />} />
          <Route path="/admin/all-customers" element={<AllCustomers />} />
          <Route path="/admin/acess-role" element={<AcessRole />} />
          <Route path="/admin/profile/:id" element={<Profile />} />
          <Route path="/admin/faq" element={<Faq />} />
          <Route path="/admin/chat/:id" element={<Chat />} />
          <Route
            path="/admin/chatbot-setting/:customerId/:projectId"
            element={<ChatBotSetting />}
          />
          <Route path="/admin/onboard-thanks" element={<Onboardhome />} />
          <Route path="/sales-team" element={<SalesTeam />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>
      </Routes>
    </Router>
  );
}
