import {
  IconColorPicker,
  IconColorSwatch,
  IconEdit,
  IconHelpCircle,
  IconMessages,
  IconSearch,
  IconUsers,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ChatbotServices from "../api/chatbot/ChatbotServices";
import "../assets/css/data.scss";
import avatar14 from "../assets/img/avatars/1.png";
import persona from "../assets/img/illustrations/custom_persona.png";
import banner from "../assets/img/pages/profile-banner.png";
import "../assets/vendor/css/rtl/core.css";
import "../assets/vendor/css/rtl/theme-default.css";
import { ErrorToaster, SuccessToaster } from "./Toaster";
import {
  tooltipeight,
  tooltipeleven,
  tooltipfive,
  tooltipfour,
  tooltipfourteen,
  tooltipnine,
  tooltipnineA,
  tooltipone,
  tooltipseven,
  tooltipsix,
  tooltipten,
  tooltipthirteen,
  tooltipthree,
  tooltiptwelve,
  tooltiptwo,
} from "./chatToolTip";

export default function Chattab({ projectId }) {
  const [check, setCheck] = useState(1);
  const [color, setColor] = useState();
  const colorInputRef = useRef(null);
  const [showInput, setShowInput] = useState(false);

  const { register, handleSubmit, setValue } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    setValue: setValue3,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const handleOff = () => {
    setShowInput();
  };

  const handleChange = (val) => {
    setCheck(val);
  };

  const handleUploadAvatar = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      await ChatbotServices.uploadChatbotAvatar(projectId, formData);
      SuccessToaster("Avatar upload successfully");
      getChatbotSetting();
    } catch (error) {
      console.log(
        "🚀 ~ file: dataTab.js:93 ~ handleUploadSitemap ~ error:",
        error
      );
    }
  };

  const handleUploadBg = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      await ChatbotServices.uploadChatbotBg(projectId, formData);
      SuccessToaster("Background Image upload successfully");
      getChatbotSetting();
    } catch (error) {
      console.log(
        "🚀 ~ file: dataTab.js:93 ~ handleUploadSitemap ~ error:",
        error
      );
    }
  };

  function handleIconClick() {
    colorInputRef.current.click();
  }

  const [chatbotSetting, setChatbotSetting] = useState();

  const getChatbotSetting = async () => {
    try {
      let params = {
        projectId: projectId,
      };
      const { data } = await ChatbotServices.getChatbotSetting(params);
      setChatbotSetting(data);
      setColor(data?.chatbot_color);
      setValue("default_prompt", data?.default_prompt);
      setValue("chatbot_siesta_msg", data?.chatbot_siesta_msg);
      setValue(
        "citations_sources_label_msg",
        data?.citations_sources_label_msg
      );
      setValue(
        "citations_answer_source_label_msg",
        data?.citations_answer_source_label_msg
      );

      setValue2("response_source", data?.response_source);
      setValue2("chatbot_msg_lang", data?.chatbot_msg_lang);
      setValue2("chatbot_model", data?.chatbot_model.toLowerCase());
      setValue2(
        "enable_citations",
        data?.enable_citations ? "enabled" : "disabled"
      );
      setValue2("citations_view_type", data?.citations_view_type);
      setValue2(
        "remove_branding",
        data?.remove_branding ? "NoBranding" : "Default"
      );
      setValue2("no_answer_message", data?.no_answer_message);
      setValue2("ending_message", data?.ending_message);

      setValue3("persona_instructions", data?.persona_instructions);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const updateChatbotSetting = async (formData) => {
    setLoading(true);
    try {
      const setting = {
        ...formData,
        no_answer_message: formData?.no_answer_message ?? undefined,
        ending_message: formData?.ending_message ?? undefined,
      };
      delete setting.chatbot_model
      if (check === 1) {
        setting.chatbot_color = color;
        setting.hang_in_there_msg = formData?.hang_in_there_msg;
      }
      if (check === 2) {
        setting.enable_citations =
          formData?.enable_citations === "enabled" ? true : false;
        setting.remove_branding =
          formData?.remove_branding === "Default" ? false : true;
      }
      let obj = {
        projectId: projectId,
        data: setting,
      };
      const { message } = await ChatbotServices.updateChatbotSetting(obj);
      SuccessToaster("Updated chatbot setting Successfully!");
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatbotSetting();
  }, []);

  return (
    <>
      <div className="settings-body mt-6 shadow-sm shadow-black rounded bg-white transition">
        <div className="setting-header border-b p-4">
          <span className="h3 fw-bold ">Chatbot Settings</span>
        </div>
        <div className="setting-content">
          <div className="row justify-content-around m-0">
            <div className="col-12 col-xl-3 left_side">
              <div
                onClick={() => handleChange(1)}
                className="row mx-2 subtab d-flex flex-col gap-3 my-4 align-items-center active cursor-pointer w-100"
              >
                <div
                  className={` ${check == 1 ? "bg-primary text-white" : "bg_grey"
                    } subtab-icon shadow-sm shadow-black rounded p-2  col-2`}
                  style={{ width: 40, height: 40 }}
                >
                  <IconColorSwatch />
                </div>
                <div className="subtab-content d-flex flex-column justify-content-between col-8">
                  <div className="subtab-title">
                    <span className="fw-bold">User Experience (UX)</span>
                  </div>
                  <div className="subtab-description">
                    <p className="text-muted m-0">UI and UX of chatbot</p>
                  </div>
                </div>
              </div>
              {/* second disable by arham on 20 nov */}
              <div
                onClick={() => handleChange(2)}
                className="row mx-2 subtab gap-3 d-flex flex-col my-4 align-items-center active cursor-pointer w-100"
              >
                <div
                  className={`${check == 2 ? "bg-primary text-white" : "bg_grey"
                    } subtab-icon shadow-sm shadow-black rounded p-2  col-2`}
                  style={{ width: 40, height: 40 }}
                >
                  <IconMessages />
                </div>
                <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-8">
                  <div className="subtab-title">
                    <span className="fw-bold">Conversation Settings</span>
                  </div>
                  <div className="subtab-description">
                    <p className="text-muted m-0">
                      Control conversational behavior
                    </p>
                  </div>
                </div>
              </div>

              {/* third */}
              <div
                onClick={() => handleChange(3)}
                className="row mx-2 subtab d-flex gap-3 flex-col my-4 align-items-center active cursor-pointer w-100"
              >
                <div
                  className={` ${check == 3 ? "bg-primary text-white" : "bg_grey"
                    } subtab-icon shadow-sm shadow-black rounded p-2 col-2`}
                  style={{ width: 40, height: 40 }}
                >
                  <IconUsers />
                </div>
                <div className="subtab-content d-flex flex-column justify-content-between gap-1 col-8">
                  <div className="subtab-title">
                    <span className="fw-bold">Custom Persona</span>
                  </div>
                  <div className="subtab-description">
                    <p className="text-muted m-0">
                      Customize how the chatbot behaves.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {check == 1 && (
              <div className="col-12 col-xl-9 right_side">
                <div className="fw-bold">
                  Avatar
                  <OverlayTrigger placement="top" overlay={tooltiptwo}>
                    <IconHelpCircle className="ml-2" />
                  </OverlayTrigger>
                </div>
                <div className="row">
                  <div className="col-4 col-md-2">
                    <img
                      className="w-100 rounded shadow-sm shadow-black"
                      src={
                        chatbotSetting?.chatbot_avatar
                          ? chatbotSetting?.chatbot_avatar
                          : avatar14
                      }
                    />
                  </div>
                  <div className="col-8 col-md-10">
                    <div className="d-flex flex-column gap-3 h-100 align-items-start justify-content-center">
                      <div className="d-flex flex-row gap-4">
                        <input
                          id="avatar"
                          onChange={handleUploadAvatar}
                          type="file"
                          className="d-none"
                        />
                        <Button
                          className="btn btn-primary"
                          onClick={() =>
                            document.getElementById("avatar").click()
                          }
                        >
                          Upload new photo
                        </Button>
                      </div>
                      <p>Allowed JPG, GIF or PNG. Max size of 800K</p>
                    </div>
                  </div>
                </div>

                <div className="fw-bold mt-3">
                  Chatbot Color
                  <OverlayTrigger placement="top" overlay={tooltipone}>
                    <IconHelpCircle className="ml-2" />
                  </OverlayTrigger>
                </div>
                <div className="d-flex color-input border border-dark">
                  <div className="d-flex">
                    <div
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        background: `${color}`,
                      }}
                    ></div>
                    <div style={{ marginLeft: "10px" }}>{color}</div>
                  </div>
                  <div>
                    <IconColorPicker
                      onClick={handleIconClick}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",

                        borderRadius: "50%",
                      }}
                    />
                    <Form.Control
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "24px",
                        height: "24px",
                      }}
                      type="color"
                      ref={colorInputRef}
                      id="exampleColorInput"
                      onChange={(e) => setColor(e.target.value)} // this will update the color state when the user picks a color
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="fw-bold mt-2">
                    Background Image
                    <OverlayTrigger placement="top" overlay={tooltipthree}>
                      <IconHelpCircle className="ml-2" />
                    </OverlayTrigger>
                  </div>
                  <div className="fw-bold mt-1 p-1">
                    <input
                      type="file"
                      id="imgupload"
                      style={{ display: "none" }}
                      onChange={handleUploadBg}
                    />
                    <a
                      variant="link"
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() =>
                        document.getElementById("imgupload").click()
                      }
                    >
                      Add New
                    </a>
                  </div>
                </div>

                <div className="container-full">
                  <img
                    width={"100%"}
                    src={
                      chatbotSetting?.chatbot_background
                        ? chatbotSetting?.chatbot_background
                        : banner
                    }
                    className="mb-0"
                  />
                </div>

                <div className="fw-bold mt-3">
                  Default Prompt{" "}
                  <OverlayTrigger placement="top" overlay={tooltipfour}>
                    <IconHelpCircle className="ml-2" />
                  </OverlayTrigger>
                </div>
                <br />
                <div className="default-prompt-container p-3 rounded ">
                  <div className="bg-white border-20">
                    <div className="d-flex bg-white p-3 shadow-sm border-top-20">
                      <IconSearch className="mx-2" />
                      <textarea
                        className="w-100 textarea_chatbot"
                        {...register("default_prompt")}
                      />
                      <IconEdit />
                    </div>
                    {/* <br/> */}
                    {/* <div className=" border-20 mt-2">
                      <div className=" border-20 text-muted d-block text-end fs-5 bg-white rounded border-20 p-0 px-2">
                        19/8000
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <div className="d-flex">
                  <div className="fw-bold mt-3">
                    Examples questions{" "}
                    <OverlayTrigger placement="top" overlay={tooltipfive}>
                      <IconHelpCircle className="ml-2" />
                    </OverlayTrigger>
                  </div>
                  <div className="fw-bold mt-3 p-1">
                    <a
                      variant="link"
                      onClick={() => setShowInput(!showInput)}
                      style={{ marginLeft: 10, cursor: "pointer" }}
                    >
                      Add New
                    </a>
                  </div>
                </div> */}

                {showInput && (
                  <>
                    <div className="bg-white border-20">
                      <div className="d-flex bg-white p-3 shadow-sm border-top-20">
                        <textarea className="w-100 textarea_chatbot border rounded border-dark">
                          Ask me anything ...
                        </textarea>
                        <br />
                      </div>

                      <div className="d-flex bg-white p-3 shadow-sm">
                        <button className="btn btn-primary">Add</button>
                        <button
                          className="btn btn-light ml-3"
                          onClick={handleOff}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                )}

                <div className="fw-bold mt-3">
                  Citations: 'Where did this answer come from?'
                  <OverlayTrigger placement="top" overlay={tooltipsix}>
                    <IconHelpCircle className="ml-2" />
                  </OverlayTrigger>
                </div>
                <div className="col-12 col-md-8 col-lg-5">
                  <input
                    className="rounded w-100 p-2"
                    style={{ height: "46px" }}
                    placeholder="Where did this answer come from?"
                    {...register("citations_answer_source_label_msg")}
                  />
                </div>
                <div className="fw-bold mt-3">
                  Citations: 'Sources'{" "}
                  <OverlayTrigger placement="top" overlay={tooltipseven}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <br />
                <div className="col-12 col-md-8 col-lg-5">
                  <input
                    className="rounded w-100 p-2"
                    style={{ height: "46px" }}
                    placeholder="Sources"
                    {...register("citations_sources_label_msg")}
                  />
                </div>
                <div className="fw-bold mt-3">Loading indicator type</div>
                <br />
                <div class="form-check mb-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Show Hang in there message"
                    checked
                    {...register("hang_in_there_msg")}
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Show "Hang in there" message
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="Show loading indicator"
                    {...register("hang_in_there_msg")}
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Show loading indicator
                  </label>
                </div>

                <div className="fw-bold mt-3">
                  Chatbot is taking a siesta{" "}
                  <OverlayTrigger placement="top" overlay={tooltipeight}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>{" "}
                </div>
                <br />
                <div className="col-12 col-md-8 col-lg-5 mb-4">
                  <input
                    className="rounded w-100 p-2"
                    style={{ height: "46px" }}
                    placeholder="Oops! The chat bot is taking a siesta. This usually happens when OpenAI is down! Please try again later."
                    {...register("chatbot_siesta_msg")}
                  />
                </div>
                <button
                  disabled={loading}
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit(updateChatbotSetting)}
                >
                  Save Changes
                </button>
              </div>
            )}
            {check == 2 && (
              <div className="col-12 col-xl-9 right_side">
                <div className="fw-bold">
                  Response source{" "}
                  <OverlayTrigger placement="top" overlay={tooltipnine}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <div>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    {...register2("response_source")}
                  >
                    <option value="default">Default</option>
                    <option value="own_content">Own Content</option>
                    <option value="openai_content">
                      Openai Content
                    </option>
                  </select>
                  <p className="mt-2 text-muted">
                    Where should response intelligence come from (Default : Your
                    Content)
                  </p>
                </div>

                <div className="fw-bold">
                  Chatbot Message Language{" "}
                  <OverlayTrigger placement="top" overlay={tooltipnineA}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <div>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    {...register2("chatbot_msg_lang")}
                  >
                    <option value="en">English - Worldwide(English)</option>
                  </select>
                  <p className="mt-2 text-muted">
                    Language for Kriss.Ai messages like "Ask Me Anything"
                    (Default: English)
                  </p>
                </div>

                <div className="fw-bold">Model </div>
                <div>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    {...register2("chatbot_model")}
                  >
                    <option value="gpt-3.5-turbo">GPT-3.5-Turbo</option>
                    <option value="gpt-4">GPT-4</option>
                  </select>
                </div>

                <br />
                <div className="fw-bold">
                  Citations{" "}
                  <OverlayTrigger placement="top" overlay={tooltipten}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <div class="form-check mb-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="Citations"
                    id="Disabled"
                    value="disabled"
                    {...register2("enable_citations")}
                  />
                  <label class="form-check-label" for="Disabled">
                    Disabled
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="Citations"
                    id="Enabled"
                    value="enabled"
                    {...register2("enable_citations")}
                  />
                  <label class="form-check-label" for="Enabled">
                    Enabled
                  </label>
                </div>

                <br />
                <div className="fw-bold">
                  Citations View{" "}
                  <OverlayTrigger placement="top" overlay={tooltipeleven}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <div class="form-check mb-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="CitationsView"
                    id="user"
                    value="user"
                    {...register2("citations_view_type")}
                  />
                  <label class="form-check-label" for="user">
                    User-Initiated
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="CitationsView"
                    id="AlwaysShow"
                    value="AlwaysShow"
                    {...register2("citations_view_type")}
                  />
                  <label class="form-check-label" for="AlwaysShow">
                    Always Show
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="CitationsView"
                    id="AlwaysHide"
                    value="AlwaysHide"
                    {...register2("citations_view_type")}
                  />
                  <label class="form-check-label" for="AlwaysHide">
                    Always Hide
                  </label>
                </div>

                <br />
                <div className="fw-bold">
                  Branding{" "}
                  <OverlayTrigger placement="top" overlay={tooltiptwelve}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <div class="form-check mb-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="Branding"
                    id="Default"
                    value="Default"
                    {...register2("remove_branding")}
                  />
                  <label class="form-check-label d-flex" for="Default">
                    Default
                    <div className="text-white mx-2 rounded bg-primary px-2">
                      POWERED BY Kriss.Ai
                    </div>
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="Branding"
                    id="NoBranding"
                    value="NoBranding"
                    {...register2("remove_branding")}
                  />
                  <label class="form-check-label d-flex" for="NoBranding">
                    No Branding
                    <div className="text-white mx-2 rounded bg-dark px-2">
                      PREMIUM PLANS ONLY
                    </div>
                  </label>
                </div>

                <br />
                <div className="fw-bold">
                  Change the "I don't know the answer" message{" "}
                  <OverlayTrigger placement="top" overlay={tooltipthirteen}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInput"
                  placeholder="I'm sorry, I don't know the answer"
                  {...register2("no_answer_message")}
                />
                <br />
                <div className="fw-bold">
                  Add a custom message ending to every Kriss.Ai response{" "}
                  <OverlayTrigger placement="top" overlay={tooltipfourteen}>
                    <IconHelpCircle className="mr-2" />
                  </OverlayTrigger>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInput"
                  placeholder="Please email us for further support"
                  {...register2("ending_message")}
                />

                <button
                  disabled={loading}
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleSubmit2(updateChatbotSetting)}
                >
                  Save Changes
                </button>
              </div>
            )}
            {check == 3 && (
              <div className="col-12 col-xl-9 right_side">
                <div className="row">
                  <div className="col-9">
                    <div className="mb-2">
                      <span className="fw-bold"> [Advanced Users]</span>{" "}
                      Customize your chatbot behavior by adjusting the system
                      parameter to control its personality traits and role.
                    </div>
                    <div className="mb-4">
                      <div class="form-outline">
                        <textarea
                          class="form-control"
                          id="textAreaExample1"
                          rows="4"
                          placeholder="You are a custom chatbot assistant called *bot name*, a friendly *bot role* who works for *organization* and answers questions based on the given context. Be as helpful as possible. Always prioritize the customer. Escalate complex issues. Stay on topic. Use appropriate language, Acknowledge limitations."
                          {...register3("persona_instructions")}
                        />
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-start"
                      style={{ gap: "8px" }}
                    >
                      <button
                        disabled={loading}
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit3(updateChatbotSetting)}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                  <div className="col-3 col-xs-12">
                    <img
                      src={persona}
                      style={{ width: "100%", height: "auto" }}
                      alt="persona image"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
