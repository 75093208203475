import { IconX } from "@tabler/icons-react";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "../assets/css/sidebar.css";
import ContactModal from "../components/contactModal";
import Layout from "../layout";

export default function Contact() {
  const [show, setShow] = useState(false);
  // input fields array
  const inputFields = [
    {
      fieldName: "Subject",
      filedType: "text",
      isRequired: true,
    },
    {
      fieldName: "Work Email",
      filedType: "email",
      isRequired: true,
    },
    {
      fieldName: "Message",
      isRequired: false,
      isTextArea: true,
    },
  ];
  const [fields, setFields] = useState(inputFields);

  // modal display controller
  const handleShow = () => {
    setShow(true);
  };

  // data from modal
  const handleSubmit = (data) => {
    setFields([
      {
        ...data,
        isRequired: data.isRequired === "true" ? true : false,
      },
      ...fields,
    ]);
    console.log();
  };

  const handleDelete = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };
  return (
    <Layout>
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h2 className="settings-header mt-6"></h2>
            <div className="contact-main">
              <div class="row">
                <div class="col-lg-12 col-xxl-12 mb-4 order-xxl-1">
                  <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                      <div class="card-title mb-0">
                        <h1 className="text-center fw-bold">Give us a Shout</h1>
                        <h5 class="m-0 me-2 text-center">
                          Let us know how we can help. Want quick support? Try
                          out our live Kriss.Ai chatbot that has indexed all of
                          our content. Yes – it’s learnt all about our website,
                          FAQ, blog, documentation, API and more.
                        </h5>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div class="float-right text-right mb-2">
                          <button
                            type="button"
                            class="btn btn-warning"
                            onClick={handleShow}
                          >
                            Add New
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="content-card py-4 container">
                      <Form>
                        {fields.map((input, index) => (
                          <Form.Group className="mt-2 text-center">
                            <Form.Label className="fw-bold ">
                              {input.fieldName}
                            </Form.Label>
                            <span className="d-flex gap-3">
                              <Form.Control
                                type={
                                  input.filedType !== "textarea" &&
                                  input.filedType
                                }
                                as={input.isTextArea && "textarea"}
                                rows={1}
                                required={input.isRequired}
                              />
                              <Button variant="danger">
                                <IconX onClick={() => handleDelete(index)} />
                              </Button>
                            </span>
                          </Form.Group>
                        ))}

                        <div className="row">
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              className="mt-4 rounded-pill px-5 "
                              variant="primary"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Form>

                      <div className="contact-down mt-4 container">
                        <div className="row">
                          <div className="col-lg-6">
                            <h5 className="fw-bold">Sales</h5>
                            <p>
                              Let us show you what Kriss.Ai can do for your
                              team. Get in touch at hello@Kriss.Ai.ai
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h5 className="fw-bold">Customer Support</h5>
                            <p>
                              For any product or technical questions, check out
                              our FAQ, blog, documentation and API or reach us
                              at hello@Kriss.Ai.ai
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h5 className="fw-bold">Media & Press</h5>
                            <p>
                              Excited by the Kriss.Ai products and want to share
                              it with your audience? For press inquiries,
                              contact: hello@Kriss.Ai.ai
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h5 className="fw-bold">General Inquiries</h5>
                            <p>
                              For anything else on your mind, including
                              partnership ideas, say hello: hello@Kriss.Ai.ai
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactModal setShow={setShow} show={show} onSave={handleSubmit} />
          <div class="content-backdrop fade"></div>
        </div>
      </div>
    </Layout>
  );
}
