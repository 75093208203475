const CustomerRoutes = {
    getCustomers: 'admin/customers',
    getCustomerDetail: 'admin/customers/',
    getCustomerStripeLink: 'admin/customer-stripe/',
    updateCustomer: 'admin/updateProfile',
    updateCustomerImage: 'admin/uploadImage',
    getCustomerAccountInfo: 'admin/customers/',
    customerAccountSetup: 'admin/customers/',
    deleteCustomer: 'admin/customers/',
    getCustomerCurrentPlan: 'admin/currentplan/',
    getCustomerPaymentHistory: 'admin/payment/history/',
};

export default CustomerRoutes