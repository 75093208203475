import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const Spinner = () => (
  <div
    style={{
      width: "100%",
      minHeight: "20vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 30,
            color: "#61d9ca",
          }}
          spin
        />
      }
    />
  </div>
);

export default Spinner;