import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/admin.css";
import Layout from "../layout";
import { ErrorToaster, SuccessToaster } from "../components/Toaster";
import { IconEye } from "@tabler/icons-react";
import { IconEyeOff } from "@tabler/icons-react";
import { useForm } from "react-hook-form";
import AuthServices from "../api/auth/AuthService";

const ChangePassword = () => {

  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const password = useRef({});
  password.current = watch("newPassword", "");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmpass, setShowConfirmpass] = useState(false);
  const [newPassword, setNewPassword] = useState(false);

  useEffect(() => {
    document.body.classList.add("modal-style-blur");
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-style-blur");
    };
  }, []);

  const changePassword = async (formData) => {
    try {
      let params = {
        oldpassword: formData?.oldPassword,
        password: formData?.newPassword,
        cpassword: formData?.confirmPassword,
      }
      const { message } = await AuthServices.changePassword(params)
      SuccessToaster(message)
      reset()
    } catch (error) {
      ErrorToaster(error)
    }
  }

  return (
    <Layout>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <h4 class="py-3 mb-2">
            <span class="text-muted fw-light">Change Password</span>
          </h4>

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <h5 className="card-header">Change Password</h5>
                <div className="card-body">
                  <form id="formAccountSettings" method="POST" onsubmit="return false">
                    <div className="row">
                      <div className="mb-3 col-md-6 form-password-toggle">
                        <label className="form-label" for="currentPassword">
                          Current Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            name="currentPassword"
                            id="currentPassword"
                            type={`${!showPassword ? "password" : "text"}`}
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            {...register('oldPassword', {
                              required: 'Please enter old password'
                            })}
                          />
                          <span className=" input-group-text cursor-pointer">
                            {showPassword ? (
                              <IconEye onClick={() => setShowPassword(false)} />
                            ) : (
                              <IconEyeOff onClick={() => setShowPassword(true)} />
                            )}
                          </span>
                        </div>
                        {errors &&
                          <p className={'error'}>{errors?.oldPassword?.message}</p>
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 form-password-toggle">
                        <label className="form-label" for="newPassword">
                          New Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            type={`${!newPassword ? "password" : "text"}`}
                            id="newPassword"
                            name="newPassword"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            {...register('newPassword', {
                              required: 'Please enter password',
                              minLength: {
                                value: 6,
                                message: "Password should be atleast 6 characters",
                              },
                            })}
                          />
                          <span className=" input-group-text cursor-pointer">
                            {newPassword ? (
                              <IconEye onClick={() => setNewPassword(false)} />
                            ) : (
                              <IconEyeOff onClick={() => setNewPassword(true)} />
                            )}
                          </span>
                        </div>
                        {errors &&
                          <p className={'error'}>{errors?.newPassword?.message}</p>
                        }
                      </div>

                      <div className="mb-3 col-md-6 form-password-toggle">
                        <label className="form-label" for="confirmPassword">
                          Confirm New Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            type={`${!showConfirmpass ? "password" : "text"}`}
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                            {...register('confirmPassword', {
                              required: 'Please enter the confirm password',
                              validate: value => value === password.current || "Confirm password does not match."
                            })}
                          />
                          <span className=" input-group-text cursor-pointer">
                            {showConfirmpass ? (
                              <IconEye onClick={() => setShowConfirmpass(false)} />
                            ) : (
                              <IconEyeOff onClick={() => setShowConfirmpass(true)} />
                            )}
                          </span>
                        </div>
                        {errors &&
                          <p className={'error'}>{errors?.confirmPassword?.message}</p>
                        }
                      </div>
                      <div className="col-12 mb-4">
                        <h6>Password Requirements:</h6>
                        <ul className="ps-3 mb-0">
                          <li className="mb-1">
                            Minimum 6 characters long - the more, the better
                          </li>
                          {/* <li className="mb-1">At least one lowercase character</li>
                  <li>At least one number, symbol, or whitespace character</li> */}
                        </ul>
                      </div>
                      <div>
                        <button type="submit" className="btn btn-primary me-2" onClick={handleSubmit(changePassword)}>
                          Save changes
                        </button>
                        {/* <button type="reset" className="btn btn-label-secondary">
                          Cancel
                        </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
